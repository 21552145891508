import React, { PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useLogout } from '@aviobook/_hooks';
import { toastWarning } from '@aviobook/Toastify';
import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import Cookies from 'js-cookie';
import { COOKIE_ACCESS_TOKEN_NAME, ErrorResponse, hasExpired, HttpClient, useAuthenticationContext } from 'shared';
import { refreshAccessToken } from 'shared/src/queries/auth';
import { ResponseErrorCodesV1 } from 'types';

interface RetryQueueItem<T = unknown> {
  config: AxiosRequestConfig;
  reject: (error?: AxiosError) => void;
  resolve: (value: AxiosResponse<T> | PromiseLike<AxiosResponse<T>>) => void;
}

const refreshAndRetryQueue: RetryQueueItem[] = [];
let isRefreshing = false;

const ME_ENDPOINT_SUFFIX = 'auth/me';

export const AxiosInterceptorProvider = ({ children }: PropsWithChildren) => {
  const [isInterceptorReady, setIsInterceptorReady] = useState(false);
  const { setAccessToken } = useAuthenticationContext();
  const logout = useLogout();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const onError = useCallback(
    async (response: ErrorResponse) => {
      const isAdminRoute = location.pathname.startsWith('/admin');

      switch (response.status) {
        case 403: {
          // If we get back a 403 from auth/me on admin endpoint, we navigate back to "regular" connect web.
          // We do this by navigating to ''.
          // Better handling of all this should be covered in AVIO-43058
          if (isAdminRoute && response.config?.url?.endsWith(ME_ENDPOINT_SUFFIX)) {
            toastWarning(t('ERRORS.PERMISSION_DENIED'));
            navigate('');
          }
          break;
        }
        case 401: {
          if (!isRefreshing) {
            isRefreshing = true;
            try {
              await refreshAccessToken();
              const newAccessToken = Cookies.get(COOKIE_ACCESS_TOKEN_NAME);
              if (!newAccessToken || hasExpired(newAccessToken)) {
                logout();
                setAccessToken(null);
                refreshAndRetryQueue.length = 0;
                return;
              }
              setAccessToken(newAccessToken);

              // Retry all requests in the queue after refreshing the token
              refreshAndRetryQueue.forEach(({ config, reject, resolve }) => {
                HttpClient.retry(config)
                  .then((response: AxiosResponse) => resolve(response))
                  .catch((error: AxiosError) => reject(error));
              });

              refreshAndRetryQueue.length = 0;
            } catch (refreshError) {
              const error = refreshError as AxiosError;
              refreshAndRetryQueue.forEach(({ reject }) => reject(error));
              refreshAndRetryQueue.length = 0;
              logout();
            } finally {
              isRefreshing = false;
            }
          } else {
            return new Promise<AxiosResponse>((resolve, reject) => {
              refreshAndRetryQueue.push({ config: response.config, reject, resolve });
            });
          }
          break;
        }
        case 404:
          if (response.config?.url?.endsWith(ME_ENDPOINT_SUFFIX)) {
            logout();
          }
          break;
        default:
          break;
      }

      switch (response.data.code) {
        case ResponseErrorCodesV1.INSUFFICIENT_PERMISSIONS:
          if (isAdminRoute) {
            navigate('/admin');
          }
          break;
        case ResponseErrorCodesV1.USER_INACTIVE:
        case ResponseErrorCodesV1.UNAUTHORIZED:
          logout();
          break;
        default:
          return;
      }
    },
    [logout, navigate, setAccessToken, t],
  );

  useEffect(() => {
    HttpClient.setInstances(onError);
    setIsInterceptorReady(true);
    return () => {
      HttpClient.resetInstances();
    };
  }, [onError]);

  if (!isInterceptorReady) {
    return null; // Avoid rendering children if the interceptor isn't ready
  }

  return <>{children}</>;
};
