import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SideModal, SideModalHeader } from '@aviobook/_shared';
import { FlightroomInfoItem } from '@aviobook/flightrooms/_components/flightroomInfoItem/FlightroomInfoItem';
import { CrewProfile } from '@aviobook/profile/CrewProfile';
import { useFlightroomsContext, useMentions } from 'shared';
import { COLORS } from 'styles';
import { FlightRoomUser } from 'types';

type MentionInfoSideModalProps = {
  activeMention?: string | null;
  onClose: () => void;
};

type Screen = 'USERS' | 'USER_DETAILS';

export const MentionInfoSideModal: FC<MentionInfoSideModalProps> = ({ activeMention, onClose }) => {
  const { selectedFlightroom } = useFlightroomsContext();
  const { t } = useTranslation();

  const [screenToDisplay, setScreenToDisplay] = useState<Screen>('USERS');
  const [activeUser, setActiveUser] = useState<FlightRoomUser | null>(null);

  const { getUsedMentions } = useMentions();

  useEffect(() => {
    if (!activeMention) {
      setScreenToDisplay('USERS');
    }
  }, [activeMention]);

  const getUsers = () => {
    const mention = activeMention && getUsedMentions(activeMention)?.[0];
    return mention && selectedFlightroom?.users?.filter(user => mention?.users.includes(user.externalId));
  };

  const handleUserClick = (user: FlightRoomUser) => {
    setActiveUser(user);
    setScreenToDisplay('USER_DETAILS');
  };

  const renderFlightRoomUsers = () => {
    const flightRoomUsers = getUsers();
    return (
      flightRoomUsers && (
        <>
          {flightRoomUsers?.map(crewMember => (
            <FlightroomInfoItem crewMember={crewMember} key={crewMember.externalId} onClick={handleUserClick} />
          ))}
        </>
      )
    );
  };

  const renderUserInfo = () => {
    return activeUser ? <CrewProfile crewMember={activeUser} /> : null;
  };

  const renderContent = () => {
    switch (screenToDisplay) {
      case 'USER_DETAILS':
        return renderUserInfo();
      case 'USERS':
      default:
        return renderFlightRoomUsers();
    }
  };

  const renderHeader = (): SideModalHeader => {
    switch (screenToDisplay) {
      case 'USER_DETAILS':
        return {
          backgroundColor: COLORS.victor.$03,
          leftIcon: {
            iconName: 'arrowLeft',
            onClick: () => setScreenToDisplay('USERS'),
          },
          title: { props: { uppercase: false }, text: t('PROFILE.HEADER_TITLE') },
        };
      case 'USERS':
      default:
        return {
          rightIcon: { iconName: 'closeCross', onClick: onClose },
          title: { props: { uppercase: false }, text: activeMention || '' },
        };
    }
  };

  return (
    <SideModal
      content={renderContent()}
      fullwidth={screenToDisplay === 'USER_DETAILS'}
      header={renderHeader()}
      isVisible={!!activeMention}
      onBackgroundClick={onClose}
    />
  );
};
