import React, { ComponentType } from 'react';
import { components, GroupBase, MultiValueRemoveProps } from 'react-select';
import { Icon } from '@aviobook/_shared';
import { Option } from '@aviobook/_types/Option';
import { COLORS } from 'styles';

export const MultiValueRemove: ComponentType<MultiValueRemoveProps<Option, boolean, GroupBase<Option>>> | undefined = ({
  ...props
}) => (
  <components.MultiValueRemove {...props}>
    <Icon colorName={COLORS.yuma.$01} name="close" />
  </components.MultiValueRemove>
);
