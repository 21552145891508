import { NestedObjectsPathKeys, Nullable, TypeFromNestedObjectsPathKeys } from 'types';

import BaseFiql from '../types/base.interface';
import Operators from '../types/fiql-operators.enum';

export default abstract class BaseFiqlNode<
  Op extends Operators,
  T extends object = { [key: string]: unknown },
  K = NestedObjectsPathKeys<Required<T>>,
  Arg = Op extends Operators.IN | Operators.OUT
    ? Nullable<Required<TypeFromNestedObjectsPathKeys<T, K>>>[]
    : Nullable<Required<TypeFromNestedObjectsPathKeys<T, K>>>,
> implements BaseFiql
{
  protected value: K;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  protected operator: Op;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  protected arg: Arg;

  public constructor(value: K, arg: Arg) {
    this.value = value;

    if (arg !== undefined) {
      this.arg = arg;
    }
  }

  protected transform(arg: Arg): string {
    return String(arg);
  }

  public parse(): string {
    const arg = this?.arg instanceof Array ? `(${this.transform(this.arg)})` : this.transform(this.arg);
    return `${this.value}${this.operator}${arg}`;
  }
}
