import React, { FC, useRef } from 'react';
import ReactDatePicker from 'react-datepicker';
import { useInputError } from '@aviobook/_hooks';
import { Icon, Text } from '@aviobook/_shared';
import InputWrapper, { InputWrapperProps } from '@aviobook/_shared/input/InputWrapper';
import { COLORS } from 'styles';

import 'react-datepicker/dist/react-datepicker.css';
import './datePicker.scss';

type Props = InputWrapperProps & {
  maxDate?: Date;
  minDate?: Date;
  onChange: (newDate: Date | null, name: string) => void;
  selectedValue: Date | null;
};

const CalendarButton: FC<{ onClick: () => void }> = ({ onClick }) => {
  return (
    <Icon
      buttonSize={3.5}
      className="calendar-icon"
      colorName={COLORS.yuma.$01}
      name={'calendarOutline'}
      onClick={onClick}
      size={2}
    />
  );
};

const ClearButton: FC<{ onClear: () => void; selectedValue: Date | null }> = ({ onClear, selectedValue }) => {
  if (selectedValue) {
    return <Icon buttonSize={3.5} className="clear-icon" colorName={COLORS.zulu.$08} name={'close'} onClick={onClear} size={2} />;
  }
  return <></>;
};

export const DatePicker: FC<Props> = ({ label, maxDate, minDate, onChange, selectedValue, ...wrapperProps }) => {
  const { name, validation } = wrapperProps;
  const { setDirty, showError } = useInputError(validation);
  const datePickerRef = useRef<ReactDatePicker>(null);

  const onClear = () => {
    onChange(null, name);
  };

  const onChangeInternal = (value: Date | null) => {
    onChange(value, name);
  };

  return (
    <InputWrapper {...wrapperProps} showError={showError}>
      <Text as="span" className="date-picker__label" color={COLORS.zulu.$06} size="XS" weight="bold">
        {label}
      </Text>
      <div className="date-picker__input">
        <ReactDatePicker
          calendarStartDay={1}
          className="date-picker"
          dateFormat={'yyyy-MM-dd'}
          maxDate={maxDate}
          minDate={minDate}
          onChange={date => {
            onChangeInternal(date);
            setDirty();
          }}
          placeholderText="YYYY-MM-DD"
          ref={datePickerRef}
          selected={selectedValue}
        />
        <div className="date-picker__button">
          <ClearButton onClear={onClear} selectedValue={selectedValue} />
          <CalendarButton onClick={() => datePickerRef?.current?.setFocus()} />
        </div>
      </div>
    </InputWrapper>
  );
};
