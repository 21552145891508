import React, { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { components, DropdownIndicatorProps, MultiValue, SingleValue } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { useInputError } from '@aviobook/_hooks';
import InputWrapper, { InputWrapperProps } from '@aviobook/_shared/input/InputWrapper';
import { Option } from '@aviobook/_types/Option';
import { generateTimeOptions, mapToOption } from '@aviobook/_utils/option';
import { isSimpleTimeString } from 'shared';
import { COLORS } from 'styles';

import { Icon } from '../../icon/Icon';
import { ClearIndicator } from '../clearIndicator/ClearIndicator';

import './timePicker.scss';

type TimePickerProps = InputWrapperProps & {
  minutesInterval?: number;
  onChange: (newValue: Option | null, name: string) => void;
  selectedValue: Option | null;
};

const DropdownIndicator = ({ ...props }: DropdownIndicatorProps<Option, false>) => (
  <components.DropdownIndicator {...props}>
    {props.children}
    <Icon colorName={COLORS.yuma.$01} name={'clockScheduleOutline'} />
  </components.DropdownIndicator>
);

export const TimePicker: FC<TimePickerProps> = ({ minutesInterval = 30, onChange, selectedValue, ...wrapperProps }) => {
  const { t } = useTranslation();
  const [customOption, setCustomOption] = useState<Option | null>(null);
  const { name, validation } = wrapperProps;
  const { setDirty, showError } = useInputError(validation);

  const internalOnChange = (newValue: SingleValue<Option> | MultiValue<Option>) => {
    onChange(newValue as SingleValue<Option>, name);
    setDirty();
  };

  const onCreateOption = (value: string) => {
    const newOption: Option = mapToOption(value);
    setCustomOption(newOption);
    onChange(newOption, name);
  };

  const generatedTimeOptions = useMemo(() => {
    return generateTimeOptions(minutesInterval);
  }, [minutesInterval]);

  const timeOptions = useMemo(() => {
    if (customOption) {
      return ([...generatedTimeOptions, customOption] as Option[]).sort((a: Option, b: Option) => a.label.localeCompare(b.label));
    }

    return generatedTimeOptions;
  }, [customOption, generatedTimeOptions]);

  const isValidNewOption = (value: string) => {
    return isSimpleTimeString(value) && !timeOptions.find(option => option.value === value);
  };

  return (
    <InputWrapper {...wrapperProps} showError={showError}>
      <CreatableSelect
        className={'time-picker'}
        classNamePrefix="time-picker"
        components={{
          ClearIndicator,
          DropdownIndicator,
        }}
        isClearable
        isValidNewOption={isValidNewOption}
        noOptionsMessage={() => t('TIME_PICKER.EMPTY_LIST')}
        onChange={internalOnChange}
        onCreateOption={onCreateOption}
        options={timeOptions}
        placeholder={t('TIME_PICKER.PLACEHOLDER')}
        value={selectedValue}
      />
    </InputWrapper>
  );
};
