import { createContext, PropsWithChildren, useCallback, useContext, useState } from 'react';
import { SelectedFlightroomTab } from 'types';

import { AppContextType } from './appContext.type';

const AppContext = createContext<AppContextType>({
  imagePreview: null,
  isCurrentlySelectedTabEmpty: false,
  isEmptySearch: false,
  selectedFlightroomTab: 'active',
  setImagePreview: () => {},
  setIsCurrentlySelectedTabEmpty: () => {},
  setIsEmptySearch: () => {},
  setSelectedFlightroomTab: () => {},
});

export const useAppContext = () => useContext(AppContext);

export const AppContextProvider = ({ children }: PropsWithChildren) => {
  const [selectedFlightroomTab, setSelectedFlightroomTab] = useState<SelectedFlightroomTab>('active');
  const [isEmptySearch, setIsEmptySearch] = useState(false);
  const [isCurrentlySelectedTabEmpty, setIsCurrentlySelectedTabEmpty] = useState(true);
  const [imagePreview, setImagePreview] = useState<string | null>(null);

  const setImagePreviewCallback = useCallback((arg: string | null) => {
    setImagePreview(arg);
  }, []);

  return (
    <AppContext.Provider
      value={{
        imagePreview,
        isCurrentlySelectedTabEmpty,
        isEmptySearch,
        selectedFlightroomTab,
        setImagePreview: setImagePreviewCallback,
        setIsCurrentlySelectedTabEmpty,
        setIsEmptySearch,
        setSelectedFlightroomTab,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
