import { FC, useEffect } from 'react';
import classNames from 'classnames';
import { COLORS } from 'styles';

import { Icon } from '../../../_shared';

import './imagePreview.scss';

type Props = {
  image: string;
  isFullScreen?: boolean;
  onClose?: () => void;
};

export const ImagePreview: FC<Props> = ({ image, isFullScreen, onClose }) => {
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape' && onClose) {
        onClose();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [onClose]);

  return (
    <div
      className={classNames('chat-image-preview-container', {
        isFullScreen,
      })}
    >
      <Icon
        className="close-icon"
        colorName={isFullScreen ? COLORS.yuma.$01 : undefined}
        name="circularCrossFullOutline"
        onClick={onClose}
        size={2.5}
      />
      <img className="image-preview" crossOrigin="anonymous" src={image} />
    </div>
  );
};
