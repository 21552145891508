import { FC, FormEvent, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InputField } from '@aviobook/_shared';
import { Button, FlexComponent } from '@aviobook/cms/_shared';
import Modal from '@aviobook/modal/Modal';
import { Keyword } from 'types';

type Props = {
  closeModal: () => void;
  onsubmit: (keyword: Keyword) => void;
};

export const AddKeywordModal: FC<Props> = ({ closeModal, onsubmit: submitForm }) => {
  const { t } = useTranslation();

  const [keyword, setKeyword] = useState<string>('');
  const [description, setDescription] = useState<string>('');

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    submitForm({
      description,
      keyword,
    });
  };

  const handleChange = (value: string | number, name: string) => {
    if (typeof value === 'string') {
      switch (name) {
        case 'keyword':
          setKeyword(value);
          break;
        case 'description':
          setDescription(value);
          break;
      }
    }
  };

  const disabled = useMemo(() => {
    return keyword.trim().length === 0 || description.trim().length === 0;
  }, [description, keyword]);

  return (
    <Modal>
      <Modal.Header onCancel={closeModal}>ADD KEYWORD</Modal.Header>
      <form onSubmit={handleSubmit}>
        <FlexComponent flexDirection="column" gap={1} padding={2} width="full">
          <InputField label="text" name="keyword" onChange={handleChange} required value={keyword} />
          <InputField label="description" name="description" onChange={handleChange} required value={description} />
        </FlexComponent>
        <Modal.Buttons>
          <div className="buttons-left-container"></div>
          <Button isOutline onClick={closeModal} theme="transparent">
            {t('SHARED.BUTTONS.CANCEL')}
          </Button>
          <Button disabled={disabled} theme="primary" type="submit">
            ADD
          </Button>
        </Modal.Buttons>
      </form>
    </Modal>
  );
};
