import { useQuery } from '@tanstack/react-query';
import { HttpClient } from 'shared';
import { API_FACTORY } from 'shared/src/utils/factories/api.factory';
import { ApiError, FlightRoom, QueryKeys } from 'types';

export enum FlightroomFilterType {
  AIRCRAFT = 'aircraft',
  ATC_CALL_SIGN = 'atcCallSign',
  DEPARTURE_AIRPORT_ICAO = 'departure.airport.icao',
  DEPARTURE_GATE = 'departure.gate',
  DESK = 'desk',
  DESTINATION_AIRPORT_ICAO = 'destination.airport.icao',
  DESTINATION_GATE = 'destination.gate',
  FLIGHT_NUMBER = 'flightNumber',
}

export const getFlightroom = async (roomId: FlightRoom['id']): Promise<FlightRoom> =>
  HttpClient.get<FlightRoom>(API_FACTORY.v1(`rooms/${encodeURIComponent(roomId)}`));

export const getFlightroomFilter = (filterName: FlightroomFilterType): string => API_FACTORY.v2(`rooms/${filterName}`);

export const useGetFlightroom = (roomId: FlightRoom['id']) =>
  useQuery<FlightRoom, ApiError>([QueryKeys.GET_FLIGHTROOM, roomId], () => getFlightroom(roomId), {
    enabled: !!roomId,
  });
