import { FC, ReactNode } from 'react';
import { IconName } from 'assets';
import classnames from 'classnames';
import { ValidatorResponse } from 'shared';

import { ErrorMessage } from '../errorMessage/ErrorMessage';
import { Icon } from '../icon/Icon';

import './inputWrapper.scss';

/**
 * This component serves as a wrapper around the specific input components. It contains some common input logic:
 *  - Show input label and icon
 *  - Show error message if needed
 *  - Show whether a field is required
 */

export type InputWrapperProps = {
  className?: string;
  disabled?: boolean;
  label?: string;
  labelIcon?: IconName;
  name: string;
  required?: boolean;
  showInlineBottomError?: boolean;
  testId?: string;
  validation?: ValidatorResponse;
};

const InputWrapper: FC<InputWrapperProps & { children: ReactNode; showError?: boolean }> = ({
  children,
  className = '',
  disabled,
  label,
  labelIcon,
  name,
  required,
  showError,
  showInlineBottomError = false,
  testId,
  validation,
}) => {
  const attributes = {
    ['data-test']: testId,
  };

  return (
    <div {...attributes} className={classnames('input-wrapper', { disabled, error: showError }, className)}>
      {!!label && (
        <label className="input-wrapper-label" htmlFor={name}>
          {!!labelIcon && <Icon name={labelIcon} />}
          <span>{label}</span>
          {required ? <sup>*</sup> : null}
        </label>
      )}
      {children}
      {showError && showInlineBottomError && <ErrorMessage>{validation?.message}</ErrorMessage>}
    </div>
  );
};

export default InputWrapper;
