import i18n from 'translations';

import { isEmptyString } from '../strings';
import { EMAIL_REGEX } from './validator.constants';

export type ValidatorResponse = {
  isValid: boolean;
  message?: string;
};

export class FormValidator {
  email(email: string): ValidatorResponse {
    const isValid = EMAIL_REGEX.test(email);
    const containsAt = email.includes('@');
    const containsDot = email.includes('.');

    if (email && !containsAt && !containsDot) {
      return {
        isValid: false,
        message: i18n.t('FORM_VALIDATION.EMAIL.MISSING_CHAR.BOTH'),
      };
    } else if (email && !containsAt) {
      return {
        isValid: false,
        message: i18n.t('FORM_VALIDATION.EMAIL.MISSING_CHAR.AT_SIGN'),
      };
    } else if (email && !containsDot) {
      return {
        isValid: false,
        message: i18n.t('FORM_VALIDATION.EMAIL.MISSING_CHAR.DOT'),
      };
    } else {
      return {
        isValid,
        message: isValid ? undefined : i18n.t('FORM_VALIDATION.EMAIL.INVALID'),
      };
    }
  }

  number(value: string): ValidatorResponse {
    const isValid = !Number.isNaN(Number(value));

    return {
      isValid,
      message: isValid ? undefined : i18n.t('FORM_VALIDATION.NUMBER.NAN'),
    };
  }

  required(value: unknown): ValidatorResponse {
    const isValid = !isEmptyString(`${value}`) && !!value;

    return {
      isValid: isValid,
      message: isValid ? undefined : i18n.t('FORM_VALIDATION.REQUIRED'),
    };
  }

  isAlphaNumeric(value: string): ValidatorResponse {
    const regex = new RegExp(/^[a-z0-9]*$/gi);
    const isValid = regex.test(value);

    return {
      isValid,
      message: isValid ? undefined : i18n.t('FORM_VALIDATION.REGEX.ALPHA_NUMERIC'),
    };
  }
}
