import { io, Socket } from 'socket.io-client';
import { OutgoingSocketEvents, RoomOpenV1 } from 'types';

import { AppConfig } from '../context';
import { getHeaders } from '../utils';

let socket: Socket | null = null;
let namespace: string | null = null;
let appConfig: AppConfig;
let socketHostUrl: string = '';

export const getSocket = (hostUrl: string, appConfiguration: AppConfig) => {
  if (!socket) {
    namespace = formatAccountToSocketNamespace(appConfiguration.account);
    appConfig = appConfiguration;
    socketHostUrl = hostUrl;
    socket = io(socketHostUrl + namespace, {
      autoConnect: false,
      extraHeaders: getHeaders(appConfig),
      path: '/api/v2/connect',
      transports: ['websocket'],
      withCredentials: true,
    });
  }
  return socket;
};

export const sendOverSocket = async <T>(event: keyof OutgoingSocketEvents, data?: T) => {
  if (!appConfig.account || !socketHostUrl) {
    return;
  }

  return getSocket(socketHostUrl, appConfig)
    .emitWithAck(event, data)
    .then(() => data);
};

export const sendRoomCloseEvent = () => {
  void sendOverSocket('room.close');
};
export const sendRoomOpenEvent = (flightroomId: string) => {
  const interaction: RoomOpenV1 = {
    room: flightroomId,
  };
  void sendOverSocket('room.open', interaction);
};

const formatAccountToSocketNamespace = (account: string): string => {
  return account.startsWith('/') ? account : `/${account}`;
};
