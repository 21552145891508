import React, { FC } from 'react';
import { Empty } from '@aviobook/_routing/empty/Empty';
import { Spinner } from '@aviobook/_shared';
import { Chat } from '@aviobook/chat/Chat';
import { ChatMessagesContextProvider, useFlightroomsContext } from 'shared';

type Props = {
  isLoading: boolean;
};

export const ChatWrapper: FC<Props> = ({ isLoading }) => {
  const { selectedFlightroom } = useFlightroomsContext();

  if (isLoading) {
    return <Spinner size="large" />;
  }

  if (!selectedFlightroom) {
    return <Empty />;
  }

  return (
    <ChatMessagesContextProvider flightroomId={selectedFlightroom?.externalId} key={selectedFlightroom?.externalId}>
      <Chat />
    </ChatMessagesContextProvider>
  );
};
