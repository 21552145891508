import { FC, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useBreadcrumbs } from '@aviobook/_hooks/';
import { BreadCrumbType } from '@aviobook/_types/BreadCrumb';
import { useShallow } from 'zustand/react/shallow';

import { BreadCrumb } from './BreadCrumb';

const breadCrumbRouteNames: BreadCrumbType[] = [{ name: 'smart actions', url: 'smart-actions' }];

export const BreadCrumbs: FC = () => {
  const location = useLocation();

  const { crumbs, init } = useBreadcrumbs(
    useShallow(state => ({
      crumbs: state.parsedCrumbs,
      init: state.init,
    })),
  );

  useEffect(() => {
    const pathNames = location.pathname
      .split('/')
      .filter(x => x)
      .map(pathName => {
        const breadCrumb = breadCrumbRouteNames.find(route => route.url === pathName);
        return breadCrumb ?? { name: pathName, url: pathName };
      });

    init(pathNames);
  }, [location, init]);

  return (
    <nav className="bread-crumb">
      <ul className="nav-bar-ul">
        {crumbs.map((crumb, index, { length }) => {
          const isLast = index === length - 1;
          return <BreadCrumb disabled={isLast} key={index} name={crumb.name} url={crumb.url} />;
        })}
      </ul>
    </nav>
  );
};
