import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { Response } from '@aviobook/_hooks/useForm';
import { Checkbox, IconWithTooltip, InputField, Text } from '@aviobook/_shared';
import { AddButton, EmptyState, FlexComponent, PageContentBlock, SaveButton, TableLayout } from '@aviobook/cms/_shared';
import { customModalStyles } from '@aviobook/cms/_shared/modal/ModalUtils';
import { isEmptyArray } from 'shared';
import { COLORS } from 'styles';
import { Keyword, SmartAction } from 'types';

import { useKeywordTable } from '../_hooks/useKeywordTable';
import { AddKeywordModal } from './AddKeywordModal';

type Props = {
  canSave: boolean;
  form: Response<SmartAction, SmartAction>;
};

export const SmartActionForm: FC<Props> = ({ canSave, form }) => {
  const { t } = useTranslation();

  const [isOpenModal, setOpenModal] = useState(false);

  const { deleteKeyword, selectedKeywords, table } = useKeywordTable({ form });

  const addingKeyword = (keyword: Keyword) => {
    form.setAttribute([...form.values.keywords, keyword], 'keywords');
    setOpenModal(false);
  };

  return (
    <>
      <Modal
        contentLabel="Example Modal"
        isOpen={isOpenModal}
        onRequestClose={() => setOpenModal(false)}
        style={customModalStyles}
      >
        <AddKeywordModal closeModal={() => setOpenModal(false)} onsubmit={addingKeyword} />
      </Modal>
      <form onSubmit={form.submit}>
        <PageContentBlock
          rightButton={canSave ? <SaveButton isDisabled={!form.isDirty} /> : null}
          title={t('SMART_ACTION_FORM_SETTINGS')}
        >
          <FlexComponent flexDirection="column" gap={1} width="full">
            <FlexComponent flexDirection="row" gap={1} width="full">
              <InputField
                disabled={!canSave}
                label={t('SMART_ACTION_FORM_SMART_ACTION_NAME')}
                name="name"
                onChange={form.setAttribute}
                required
                value={form.values?.name || ''}
              />
              <InputField
                disabled={!canSave}
                label={t('SMART_ACTION_FORM_URL')}
                name="url"
                onChange={form.setAttribute}
                required
                value={form.values?.url || ''}
              />
            </FlexComponent>

            <FlexComponent flexDirection="row" gap={1} width="full">
              <InputField
                disabled={!canSave}
                label={t('SMART_ACTION_FORM_APIKEY')}
                name="apiKey"
                onChange={form.setAttribute}
                value={form.values?.apiKey || ''}
              />
              <InputField
                disabled={!canSave}
                label={t('SMART_ACTION_FORM_DESCRIPTION')}
                name="description"
                onChange={form.setAttribute}
                value={form.values?.description || ''}
              />
            </FlexComponent>
            <div className="smart-action-request-response-row">
              <div className="checkbox-container">
                <Checkbox
                  checked={form.values?.isRequestPublic}
                  disabled={!canSave}
                  label={t('SMART_ACTION_FORM_PUBLIC_REQUEST')}
                  name="isRequestPublic"
                  onChange={form.setAttribute}
                />
                <IconWithTooltip iconName={'circularInfoOutline'} label={t('SMART_ACTION_FORM_PUBLIC_REQUEST_TOOLTIP')} />
              </div>
              <div className="checkbox-container">
                <Checkbox
                  checked={form.values?.isResponsePublic}
                  disabled={!canSave}
                  label={t('SMART_ACTION_FORM_PUBLIC_RESPONSE')}
                  name="isResponsePublic"
                  onChange={form.setAttribute}
                />
                <IconWithTooltip iconName={'circularInfoOutline'} label={t('SMART_ACTION_FORM_PUBLIC_RESPONSE_TOOLTIP')} />
              </div>
            </div>
          </FlexComponent>
        </PageContentBlock>
      </form>

      <div className="title-subtitle">
        <Text color={COLORS.zulu.$08} size="XL" weight="regular">
          {t('SMART_ACTION_FORM_KEYWORDS')}
        </Text>
        <Text color={COLORS.zulu.$08} size="SM" weight="regular">
          {t('SMART_ACTION_FORM_KEYWORDS_INFO_TABLE')}
        </Text>
      </div>
      <TableLayout
        addButtonAction={() => setOpenModal(true)}
        addButtonLabel={t('SMART_ACTION_FORM_KEYWORDS_ADD_BUTTON')}
        deleteButtonAction={deleteKeyword}
        deleteButtonDisabled={!selectedKeywords?.length}
        emptyState={() => (
          <EmptyState
            button={() =>
              canSave ? <AddButton label={t('SMART_ACTION_FORM_KEYWORDS_ADD_BUTTON')} onClick={() => setOpenModal(true)} /> : null
            }
            description={t('SMART_ACTION_FORM_KEYWORDS_EMPTY_DESCRIPTION')}
            title={t('SMART_ACTION_FORM_KEYWORDS_EMPTY_TITLE')}
          />
        )}
        isAddButtonPresent={!isEmptyArray(form.values?.keywords) && canSave}
        isDeleteButtonPresent={!isEmptyArray(form.values?.keywords) && canSave}
        table={table}
      />
    </>
  );
};
