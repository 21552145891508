import { useState } from 'react';
import { Option } from '@aviobook/_types/Option';

export type FlightRoomFilter = {
  appliedFlightRoomFilters?: FlightRoomFilterState;
  flightRoomFilterState: FlightRoomFilterState;
  handleFlightRoomFilterChange: (
    value: boolean | readonly Option[] | Option | Date | null,
    name: keyof FlightRoomFilterState,
  ) => void;
  handleFlightRoomFilterReset: () => void;
  handleFlightRoomFilterSubmit: () => void;
};

export type FlightRoomFilterState = {
  activeFlights: boolean;
  aircraftRegistrations: readonly Option[];
  aircraftTypes: readonly Option[];
  arrivalFromDate: Date | null;
  arrivalFromTime: Option | null;
  arrivalToDate: Date | null;
  arrivalToTime: Option | null;
  callSigns: readonly Option[];
  departureFromDate: Date | null;
  departureFromTime: Option | null;
  departureGates: readonly Option[];
  departureIcaos: readonly Option[];
  departureToDate: Date | null;
  departureToTime: Option | null;
  desks: readonly Option[];
  destinationGates: readonly Option[];
  destinationIcaos: readonly Option[];
  flightNumbers: readonly Option[];
  landedFlights: boolean;
  tasks: boolean;
};

const initialState: FlightRoomFilterState = {
  activeFlights: true,
  aircraftRegistrations: [],
  aircraftTypes: [],
  arrivalFromDate: null,
  arrivalFromTime: null,
  arrivalToDate: null,
  arrivalToTime: null,
  callSigns: [],
  departureFromDate: null,
  departureFromTime: null,
  departureGates: [],
  departureIcaos: [],
  departureToDate: null,
  departureToTime: null,
  desks: [],
  destinationGates: [],
  destinationIcaos: [],
  flightNumbers: [],
  landedFlights: true,
  tasks: false,
};

export const useFlightRoomFilter = (): FlightRoomFilter => {
  const [flightRoomFilterState, setFlightRoomFilterState] = useState<FlightRoomFilterState>(initialState);
  const [appliedFlightRoomFilters, setAppliedFlightRoomFilters] = useState<FlightRoomFilterState>(initialState);

  const handleChange = (value: boolean | readonly Option[] | Option | Date | null, name: keyof FlightRoomFilterState) => {
    setFlightRoomFilterState(prevData => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleReset = () => {
    setFlightRoomFilterState(initialState);
  };

  const handleSubmit = () => {
    setAppliedFlightRoomFilters(flightRoomFilterState);
  };

  return {
    appliedFlightRoomFilters,
    flightRoomFilterState,
    handleFlightRoomFilterChange: handleChange,
    handleFlightRoomFilterReset: handleReset,
    handleFlightRoomFilterSubmit: handleSubmit,
  };
};
