import { AttachmentResponseV1 } from 'types';

import { API_FACTORY, Headers, HttpClient } from '../utils';

export const uploadAttachment = async (file: File | { name: string; type: string; uri: string }) => {
  const formData = new FormData();

  formData.append('file', file as File);

  const headers = {
    'Content-Type': 'multipart/form-data',
  } as Headers;

  return HttpClient.post<AttachmentResponseV1>(API_FACTORY.v2('messages/attachments'), formData, headers);
};
