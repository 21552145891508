import { DefaultOptions as ReactQueryOptions, QueryClient } from '@tanstack/react-query';
import { ApiError, HttpStatus, QueryKeys } from 'types';

export type Environment = 'local' | 'development' | 'test' | 'staging' | 'production';

const nodeEnv = process.env.NODE_ENV;

function assertNodeEnv(env: string | undefined): asserts env {
  if (!env) {
    throw Error('NODE ENV must be specified');
  }
}

assertNodeEnv(nodeEnv);

export class Config {
  static get reactQuery(): ReactQueryOptions {
    const handleUnauthorizedCalls = (error: ApiError) => {
      if (error.statusCode !== HttpStatus.UNAUTHORIZED) {
        return;
      }

      new QueryClient().invalidateQueries([QueryKeys.AUTHENTICATE]);
    };
    return {
      mutations: {
        retry: false,
      },
      queries: {
        // @ts-expect-error type is ApiError but expects unknown
        onError: handleUnauthorizedCalls,
        refetchOnWindowFocus: true,
        retry: false,
        staleTime: 1000 * 60, // 1 minute
      },
    };
  }
}
