import React, { FC } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import { AvioDateTime, DATE_TIME_FORMATS } from 'shared';
import { ColorName, COLORS } from 'styles';

import { Icon, Text } from './_shared';

import 'react-toastify/dist/ReactToastify.css';
import './toastify.scss';

const CloseButton: FC<{
  closeToast?: () => void;
  color?: ColorName;
}> = ({ closeToast, color = COLORS.uniform.$01 }) => (
  <Icon colorName={color} name="closeCross" onClick={closeToast || (() => {})} size={2.4} />
);

const ToastMessageContainer: FC<{ color?: ColorName; message: string }> = ({ color = COLORS.yuma.$01, message }) => {
  const formattedTime = AvioDateTime().format(DATE_TIME_FORMATS.TIME);
  return (
    <div className="toast-message-container">
      <Text as="span" size="XS" weight="semiBold">
        {message}
      </Text>
      {formattedTime ? <Text as="span" className="toast-message-time" color={color} size="XXS" weight="semiBold"></Text> : null}
    </div>
  );
};

export const toastSuccess = (message: string) => {
  toast.success(<ToastMessageContainer message={message} />, {
    bodyClassName: 'toast-body',
    className: 'toast',
    icon: <Icon name="check" size={2} />,
  });
};

export const toastError = (message: string) => {
  toast.error(<ToastMessageContainer message={message} />, {
    bodyClassName: 'toast-body',
    className: 'toast',
    icon: <Icon name="triangleCoreAttention" size={2} />,
    progress: undefined,
  });
};

export const toastWarning = (message: string) => {
  toast.warn(<ToastMessageContainer color={COLORS.bogota.$12} message={message} />, {
    bodyClassName: 'toast-body',
    className: 'toast',
    closeButton: <CloseButton color={COLORS.bogota.$12} />,
    icon: <Icon name="exclamationDiamondOutline" size={2} />,
    progress: undefined,
  });
};

export const Toastify: FC = () => (
  <ToastContainer closeButton={<CloseButton />} closeOnClick={false} hideProgressBar position="top-right" theme="colored" />
);
