import React from 'react';
import { Icon, Text } from '@aviobook/_shared';
import { IconName } from 'assets';
import { COLORS } from 'styles';

type Props = {
  icon: IconName;
  title: string;
  value?: string;
};

export const ProfileSection = ({ icon, title, value }: Props) => {
  return (
    <div className="profile-section-mid-list-item">
      <Icon className="profile-section-mid-list-item-icon" colorName={COLORS.romeo.$07} name={icon} size={2.5} />
      <div>
        <Text color={COLORS.romeo.$07} size="SM" uppercase weight="bold">
          {title}
        </Text>
        <Text color={COLORS.zulu.$08} size="SM">
          {value || '-'}
        </Text>
      </div>
    </div>
  );
};
