import { QueryObserverResult, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { ApiError, AuthenticatedUser, HttpStatus, QueryKeys } from 'types';

import { useAuthenticationContext } from '../../context';
import { authenticate, authenticateAdmin } from '../../queries';

export const useAuthenticate = (
  admin: boolean,
  options: UseQueryOptions<AuthenticatedUser, ApiError, AuthenticatedUser, QueryKeys[]>,
) => {
  const { enableAuthentication, enableAuthenticationCms } = useAuthenticationContext();

  return useQuery([QueryKeys.AUTHENTICATE], admin ? authenticateAdmin : authenticate, {
    ...options,
    enabled: (!admin ? enableAuthentication : enableAuthenticationCms) && options?.enabled,
    staleTime: 0,
  });
};

export const handleAuthenticateError = (
  err: ApiError,
  retryCount: number,
  maxRetries: number,
  refetch: () => Promise<QueryObserverResult>,
  logout: () => Promise<void>,
): void => {
  if (err.statusCode === HttpStatus.UNAUTHORIZED) {
    if (retryCount < maxRetries) {
      retryCount++;
      setTimeout(() => {
        refetch();
      }, 500);
    } else {
      logout();
    }
  }
};
