import { FC } from 'react';
import { Text } from '@aviobook/_shared';

import { Button } from '../index';

type Props = {
  isDisabled?: boolean;
  label?: string;
  onClick: () => void;
};

export const AddButton: FC<Props> = ({ isDisabled, label, onClick }) => (
  <Button disabled={isDisabled} iconName="add" isOutline onClick={onClick} testId="add-button" theme="transparent">
    <Text as="span" weight="bold">
      {label}
    </Text>
  </Button>
);
