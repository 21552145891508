import React, { createContext, FC, ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import { Platform } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { v4 as uuidv4, validate as uuidValidate } from 'uuid';

import { useEnvConfig } from '../EnvConfigContext';
import { AppConfig, AppConfigContextType } from './AppConfigContext.type';

type Props = {
  children: ReactNode;
};

const DEVICE_ID_STORAGE_KEY = 'Avio-Device-Id';

const Storage = {
  getItem: async (key: string) => {
    if (Platform.OS === 'web') {
      return localStorage.getItem(key) ?? '';
    } else if (Platform.OS === 'android' || Platform.OS === 'ios') {
      return (await AsyncStorage.getItem(key)) ?? '';
    } else {
      return '';
    }
  },

  setItem: async (key: string, value: string) => {
    if (Platform.OS === 'web') {
      localStorage.setItem(key, value);
    } else if (Platform.OS === 'android' || Platform.OS === 'ios') {
      return await AsyncStorage.setItem(key, value);
    }
  },
};

const getDeviceId = async () => {
  let deviceId = await Storage.getItem(DEVICE_ID_STORAGE_KEY);
  if (!uuidValidate(deviceId)) {
    deviceId = uuidv4();
    await Storage.setItem(DEVICE_ID_STORAGE_KEY, deviceId);
  }
  return deviceId;
};

const AppConfigContext = createContext<AppConfigContextType | null>(null);

export const AppConfigContextProvider: FC<Props> = ({ children }) => {
  const [appConfig, setAppConfig] = useState<AppConfig>({
    account: '',
    deviceId: '',
    installBundleId: '',
    type: '',
    version: '',
  });
  const { appVersionNumber, installBundleId } = useEnvConfig();

  useEffect(() => {
    const initializeHeaders = async () => {
      const type = `CONNECT-${Platform.OS.toUpperCase()}`;
      const deviceId = await getDeviceId();
      setAppConfig({
        account: '',
        deviceId,
        installBundleId,
        type,
        version: appVersionNumber,
      });
    };

    initializeHeaders();
  }, [appVersionNumber, installBundleId]);

  const memoizedValue = useMemo(() => {
    const updateAppConfigAccount = (account: string) =>
      setAppConfig((prevState: AppConfig) => ({
        ...prevState,
        account,
      }));

    return { appConfig, updateAppConfigAccount };
  }, [appConfig]);

  return <AppConfigContext.Provider value={memoizedValue}>{children}</AppConfigContext.Provider>;
};

export const useAppConfigContext = (): AppConfigContextType => {
  const context = useContext(AppConfigContext);
  if (!context) {
    throw new Error('useAppConfigContext must be used within a AppConfigContextProvider');
  }
  return context;
};
