import { useEffect } from 'react';
import { ValidatorResponse } from 'shared';

import useToggle from './useToggle';

type Response = {
  setDirty: () => void;
  showError: boolean;
};

export default (validation?: ValidatorResponse): Response => {
  const [isDirty, setIsDirty] = useToggle(false);

  useEffect(() => {
    setIsDirty(false);
  }, [validation, setIsDirty]);

  return {
    setDirty: () => setIsDirty(true),
    showError: !!(!validation?.isValid && validation?.message && !isDirty),
  };
};
