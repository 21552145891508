import React, { ChangeEvent, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InputToolbarProps } from 'react-native-gifted-chat';
import { MentionInput, MentionInputRef, QuickReplies } from '@aviobook/chat/_components';
import prettyBytes from 'pretty-bytes';
import { toBase64, useGetQuickReplies } from 'shared';
import { COLORS } from 'styles';
import { GiftedMessage } from 'types';

import { useAppContext } from '../../../_context';
import { Button, Icon } from '../../../_shared';
import Modal from '../../../modal/Modal';
import { ModalOpener } from '../../../modal/ModalOpener';

import './inputToolbar.scss';

const MAX_IMAGE_SIZE = 1.2e7; // 12mb

type Props = InputToolbarProps<GiftedMessage> & {
  onSend: (message: string, attachment?: File | null) => void;
};

export const InputToolbar: React.FC<Props> = ({ onSend }) => {
  const inputFile = useRef<HTMLInputElement | null>(null);
  const inputMentionRef = useRef<MentionInputRef>(null);

  const { t } = useTranslation();
  const { data: quickReplies = [] } = useGetQuickReplies();

  const { imagePreview, setImagePreview } = useAppContext();
  const [image, setImage] = useState<File | null>(null);
  const [message, setMessage] = useState('');

  const onPressQuickReply = (item: string) => {
    inputMentionRef.current?.sendText(item);
  };

  const sendMessage = () => {
    onSend(message, imagePreview ? image : null);
    setMessage('');
    setImage(null);
    setImagePreview('');
  };

  const handlePickAttachment = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file?.size && file.size > MAX_IMAGE_SIZE) {
      ModalOpener.instance.open({
        render: () => (
          <Modal>
            <Modal.Header>{t('CHAT.INPUT.ALERT.ATTACHMENT_TOO_LARGE.TITLE')}</Modal.Header>
            <Modal.Content>
              {t('CHAT.INPUT.ALERT.ATTACHMENT_TOO_LARGE.DESCRIPTION', { maxSize: prettyBytes(MAX_IMAGE_SIZE) })}
            </Modal.Content>
            <Modal.Buttons>
              <Button onClick={() => ModalOpener.instance.close()} theme="primary">
                {t('SHARED.BUTTONS.OK')}
              </Button>
            </Modal.Buttons>
          </Modal>
        ),
      });
    } else if (file) {
      const base64Preview = await toBase64(file);
      setImage(file);
      setImagePreview(base64Preview);
    }
    event.target.value = '';
  };

  const sendDisabled = !message && !imagePreview;

  const openPicker = () => {
    inputFile.current?.click();
  };

  return (
    <div className="chat-input-toolbar-container">
      <QuickReplies onPressQuickReply={onPressQuickReply} quickReplies={quickReplies} />
      <div className="input-container">
        <MentionInput
          // @ts-expect-error expects ReactNode instead of Element
          icon={
            <Icon
              className={'open-attachment-picker-button'}
              colorName={COLORS.zulu.$08}
              name="circularPlusOutline"
              onClick={openPicker}
              size={2.5}
            />
          }
          message={message}
          ref={inputMentionRef}
          sendDisabled={sendDisabled}
          sendMessage={sendMessage}
          setMessage={setMessage}
        />
        <Button className="send-button" disabled={sendDisabled} onClick={sendMessage}>
          <Icon name="paperplaneOutline" size={3} />
        </Button>
      </div>
      <input accept="image/*" id="file" onChange={handlePickAttachment} ref={inputFile} style={{ display: 'none' }} type="file" />
    </div>
  );
};
