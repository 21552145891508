import { FC, useMemo } from 'react';
import { IconName } from 'assets';
import { MessageStatus } from 'types';

import { Icon } from '../icon/Icon';

type Props = {
  size?: number;
  status?: MessageStatus | null;
};

export const MessageStatusIcon: FC<Props> = ({ size = 1.5, status }) => {
  const iconName = useMemo(() => {
    const translateStatusToIcon = (status?: MessageStatus | null): IconName | undefined => {
      switch (status) {
        case MessageStatus.SENT:
          return 'singleCheckmark';
        case MessageStatus.DELIVERED:
          return 'doubleCheckmark';
        case MessageStatus.READ:
          return 'doubleCheckmarkBgCircle';
      }
    };

    return translateStatusToIcon(status);
  }, [status]);

  if (!status || ![MessageStatus.SENT, MessageStatus.DELIVERED, MessageStatus.READ].includes(status)) {
    return null;
  }

  return iconName ? <Icon name={iconName} size={size} /> : null;
};
