import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { FlightRoomFilter, FlightRoomFilterState } from '@aviobook/_hooks';
import { Button, Checkbox, Icon, Text, TimePicker, Typeahead } from '@aviobook/_shared';
import { DatePicker } from '@aviobook/_shared/input/datePicker/DatePicker';
import { FlightroomFilterType, getFlightroomFilter, useFlightroomsContext } from 'shared';
import { COLORS } from 'styles';

import './filters.scss';

export const Filters: FC<FlightRoomFilter> = ({
  flightRoomFilterState,
  handleFlightRoomFilterChange,
  handleFlightRoomFilterReset,
  handleFlightRoomFilterSubmit,
}) => {
  const { t } = useTranslation();
  const { closeFilters, isFiltersOpen } = useFlightroomsContext();

  const handleSubmit = () => {
    handleFlightRoomFilterSubmit();
    closeFilters();
  };

  const getFilterURL = (filterType: FlightroomFilterType): string => {
    return getFlightroomFilter(filterType);
  };

  if (isFiltersOpen) {
    return (
      <div className="filters-container">
        <div className="filters-title" data-test="filters-title">
          <Text as="h4" color={COLORS.zulu.$08} uppercase weight="bold">
            {t('FILTERS.TITLE')}
          </Text>
          <Icon name="closeCross" onClick={closeFilters} size={2.5} testId="filters-close-icon" />
        </div>
        <form className="filters-form">
          <div className="filters-form__block filters-form__row filters-form__row--gap">
            <div className="filters-form__col filters-form__col--gap">
              <Text as="span" className="filters-form__label" color={COLORS.zulu.$06} size="XS" weight="bold">
                {t('FILTERS.FILTER_FLIGHTS_IN')}
              </Text>
              <div style={{ display: 'flex' }}>
                <Checkbox
                  checked={flightRoomFilterState.activeFlights}
                  className="filters-form__checkbox"
                  label={t('FILTERS.ACTIVE')}
                  name="activeFlights"
                  onChange={(value, name) => handleFlightRoomFilterChange(value, name as keyof FlightRoomFilterState)}
                  testId="filters-active-flights"
                />
                <Checkbox
                  checked={flightRoomFilterState.landedFlights}
                  className="filters-form__checkbox"
                  label={t('FILTERS.LANDED')}
                  name="landedFlights"
                  onChange={(value, name) => handleFlightRoomFilterChange(value, name as keyof FlightRoomFilterState)}
                />
                <Checkbox
                  checked={flightRoomFilterState.tasks}
                  className="filters-form__checkbox"
                  label={t('FILTERS.TASKS')}
                  name="tasks"
                  onChange={(value, name) => handleFlightRoomFilterChange(value, name as keyof FlightRoomFilterState)}
                />
              </div>
            </div>
            <div className="filters-form__col filters-form__col--gap">
              <Typeahead
                label={t('FILTERS.DESK_NUMBER')}
                name="desks"
                onChange={(value, name) => handleFlightRoomFilterChange(value, name as keyof FlightRoomFilterState)}
                selectedOptions={flightRoomFilterState.desks}
                typeaheadConfig={{
                  placeholder: t('FILTERS.SELECT_ONE_OR_MORE'),
                  url: getFilterURL(FlightroomFilterType.DESK),
                }}
              />
            </div>
          </div>
          <div className="filters-form__block">
            <Text as="h5" className="filters-form__block--title" color={COLORS.zulu.$08} size="SM" uppercase weight="bold">
              {t('FILTERS.FLIGHT_AIRCRAFT')}
            </Text>
            <div className="filters-form__row">
              <div className="filters-form__col">
                <Typeahead
                  label={t('FILTERS.FLIGHT_NUMBER')}
                  name="flightNumbers"
                  onChange={(value, name) => handleFlightRoomFilterChange(value, name as keyof FlightRoomFilterState)}
                  selectedOptions={flightRoomFilterState.flightNumbers}
                  typeaheadConfig={{
                    placeholder: t('FILTERS.SELECT_ONE_OR_MORE'),
                    url: getFilterURL(FlightroomFilterType.FLIGHT_NUMBER),
                  }}
                />
              </div>
              <div className="filters-form__col">
                <Typeahead
                  label={t('FILTERS.CALL_SIGN')}
                  name="callsigns"
                  onChange={(value, name) => handleFlightRoomFilterChange(value, name as keyof FlightRoomFilterState)}
                  selectedOptions={flightRoomFilterState.callSigns}
                  typeaheadConfig={{
                    placeholder: t('FILTERS.SELECT_ONE_OR_MORE'),
                    url: getFilterURL(FlightroomFilterType.ATC_CALL_SIGN),
                  }}
                />
              </div>
            </div>
            <div className="filters-form__row">
              <div className="filters-form__col">
                <Typeahead
                  label={t('FILTERS.AIRCRAFT_TYPE')}
                  name="aircraftTypes"
                  onChange={(value, name) => handleFlightRoomFilterChange(value, name as keyof FlightRoomFilterState)}
                  selectedOptions={flightRoomFilterState.aircraftTypes}
                  typeaheadConfig={{
                    placeholder: t('FILTERS.SELECT_ONE_OR_MORE'),
                    url: getFilterURL(FlightroomFilterType.AIRCRAFT),
                  }}
                />
              </div>
              <div className="filters-form__col">
                <Typeahead
                  label={t('FILTERS.AIRCRAFT_REGISTRATION')}
                  name="aircraftRegistrations"
                  onChange={(value, name) => handleFlightRoomFilterChange(value, name as keyof FlightRoomFilterState)}
                  selectedOptions={flightRoomFilterState.aircraftRegistrations}
                  typeaheadConfig={{
                    placeholder: t('FILTERS.SELECT_ONE_OR_MORE'),
                    url: getFilterURL(FlightroomFilterType.AIRCRAFT),
                  }}
                />
              </div>
            </div>
          </div>
          <div className="filters-form__block">
            <Text as="h5" className="filters-form__block--title" color={COLORS.zulu.$08} size="SM" uppercase weight="bold">
              {t('FILTERS.AIRPORTS')}
            </Text>
            <div className="filters-form__row">
              <div className="filters-form__col">
                <Typeahead
                  label={t('FILTERS.DEPARTURE_ICAO')}
                  name="departureIcaos"
                  onChange={(value, name) => handleFlightRoomFilterChange(value, name as keyof FlightRoomFilterState)}
                  selectedOptions={flightRoomFilterState.departureIcaos}
                  typeaheadConfig={{
                    placeholder: t('FILTERS.SELECT_ONE_OR_MORE'),
                    url: getFilterURL(FlightroomFilterType.DEPARTURE_AIRPORT_ICAO),
                  }}
                />
              </div>
              <div className="filters-form__col filters-form__col--small">
                <Typeahead
                  label={t('FILTERS.DEPARTURE_GATE')}
                  name="departureGates"
                  onChange={(value, name) => handleFlightRoomFilterChange(value, name as keyof FlightRoomFilterState)}
                  selectedOptions={flightRoomFilterState.departureGates}
                  typeaheadConfig={{
                    placeholder: t('FILTERS.SELECT_ONE_OR_MORE'),
                    url: getFilterURL(FlightroomFilterType.DEPARTURE_GATE),
                  }}
                />
              </div>
              <div className="filters-form__col">
                <Typeahead
                  label={t('FILTERS.DESTINATION_ICAO')}
                  name="destinationIcaos"
                  onChange={(value, name) => handleFlightRoomFilterChange(value, name as keyof FlightRoomFilterState)}
                  selectedOptions={flightRoomFilterState.destinationIcaos}
                  typeaheadConfig={{
                    placeholder: t('FILTERS.SELECT_ONE_OR_MORE'),
                    url: getFilterURL(FlightroomFilterType.DESTINATION_AIRPORT_ICAO),
                  }}
                />
              </div>
              <div className="filters-form__col filters-form__col--small">
                <Typeahead
                  label={t('FILTERS.DESTINATION_GATE')}
                  name="destinationGates"
                  onChange={(value, name) => handleFlightRoomFilterChange(value, name as keyof FlightRoomFilterState)}
                  selectedOptions={flightRoomFilterState.destinationGates}
                  typeaheadConfig={{
                    placeholder: t('FILTERS.SELECT_ONE_OR_MORE'),
                    url: getFilterURL(FlightroomFilterType.DESTINATION_GATE),
                  }}
                />
              </div>
            </div>
          </div>
          <div className="filters-form__block">
            <Text as="h5" className="filters-form__block--title" color={COLORS.zulu.$08} size="SM" uppercase weight="bold">
              {t('FILTERS.DEPARTURE_ARRIVAL')}
            </Text>
            <div className="filters-form__row">
              <div className="filters-form__col">
                <DatePicker
                  label={t('FILTERS.DEPARTURE_FROM')}
                  name="departureFromDate"
                  onChange={(value, name) => handleFlightRoomFilterChange(value, name as keyof FlightRoomFilterState)}
                  selectedValue={flightRoomFilterState.departureFromDate}
                />
              </div>
              <div className="filters-form__col filters-form__col--small">
                <TimePicker
                  name="departureFromTime"
                  onChange={(value, name) => handleFlightRoomFilterChange(value, name as keyof FlightRoomFilterState)}
                  selectedValue={flightRoomFilterState.departureFromTime}
                />
              </div>
              <div className="filters-form__col">
                <DatePicker
                  label={t('FILTERS.DEPARTURE_TO')}
                  name="departureToDate"
                  onChange={(value, name) => handleFlightRoomFilterChange(value, name as keyof FlightRoomFilterState)}
                  selectedValue={flightRoomFilterState.departureToDate}
                />
              </div>
              <div className="filters-form__col filters-form__col--small">
                <TimePicker
                  name="departureToTime"
                  onChange={(value, name) => handleFlightRoomFilterChange(value, name as keyof FlightRoomFilterState)}
                  selectedValue={flightRoomFilterState.departureToTime}
                />
              </div>
            </div>
            <div className="filters-form__row">
              <div className="filters-form__col">
                <DatePicker
                  label={t('FILTERS.ARRIVAL_FROM')}
                  name="arrivalFromDate"
                  onChange={(value, name) => handleFlightRoomFilterChange(value, name as keyof FlightRoomFilterState)}
                  selectedValue={flightRoomFilterState.arrivalFromDate}
                />
              </div>
              <div className="filters-form__col filters-form__col--small">
                <TimePicker
                  name="arrivalFromTime"
                  onChange={(value, name) => handleFlightRoomFilterChange(value, name as keyof FlightRoomFilterState)}
                  selectedValue={flightRoomFilterState.arrivalFromTime}
                />
              </div>
              <div className="filters-form__col">
                <DatePicker
                  label={t('FILTERS.ARRIVAL_TO')}
                  name="arrivalToDate"
                  onChange={(value, name) => handleFlightRoomFilterChange(value, name as keyof FlightRoomFilterState)}
                  selectedValue={flightRoomFilterState.arrivalToDate}
                />
              </div>
              <div className="filters-form__col filters-form__col--small">
                <TimePicker
                  name="arrivalToTime"
                  onChange={(value, name) => handleFlightRoomFilterChange(value, name as keyof FlightRoomFilterState)}
                  selectedValue={flightRoomFilterState.arrivalToTime}
                />
              </div>
            </div>
          </div>
        </form>
        <div className="filters-button">
          <Button isOutline onClick={handleFlightRoomFilterReset} testId="filters-reset" theme="negative" type="reset">
            {t('FILTERS.RESET_FILTER')}
          </Button>
          <Button onClick={handleSubmit} testId="filters-submit" type="submit">
            {t('FILTERS.APPLY_FILTER')}
          </Button>
        </div>
      </div>
    );
  } else {
    return <></>;
  }
};
