import { ComponentProps, FC } from 'react';
import { Icon, Text } from '@aviobook/_shared';
import classNames from 'classnames';
import { COLORS } from 'styles';
import { SystemMessageLevel } from 'types';

import './systemMessage.scss';

type Props = {
  level?: SystemMessageLevel;
  message: string;
  time: string;
};

export const SystemMessage: FC<Props> = ({ level, message, time }) => {
  return (
    <div className={classNames('system-message-container', level)}>
      <div className="system-message">
        <Icon className="system-message-icon" size={2} {...getIconProps(level)} />
        <Text as="span" className="system-message-text" size="XS" weight="bold">
          {message}
        </Text>
      </div>
      <Text as="span" className="system-message-time" size="XS" weight="semiBold">
        {time}
      </Text>
    </div>
  );
};

const getIconProps = (systemMessageLevel?: SystemMessageLevel): ComponentProps<typeof Icon> => {
  switch (systemMessageLevel) {
    case SystemMessageLevel.ATTENTION:
      return { colorName: COLORS.zulu.$10, name: 'exclamationDiamondOutline' };
    case SystemMessageLevel.CAUTION:
      return { colorName: COLORS.zulu.$10, name: 'exclamationTriangleOutline' };
    case SystemMessageLevel.WARNING:
      return { colorName: COLORS.yuma.$01, name: 'exclamationHexagonOutline' };
    case SystemMessageLevel.OK:
      return { colorName: COLORS.zulu.$10, name: 'checkCircleOutline' };
    case SystemMessageLevel.UNCLEAR:
      return { colorName: COLORS.zulu.$10, name: 'exclamationCircleOutline' };
    case SystemMessageLevel.IMPORTED:
    case SystemMessageLevel.ACCENT:
      return { colorName: COLORS.zulu.$10, name: 'circularInfoOutline' };
    case SystemMessageLevel.UNKNOWN:
    default:
      return { colorName: COLORS.yuma.$01, name: 'circularInfoOutline' };
  }
};
