import { useCallback, useEffect, useMemo, useState } from 'react';
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, Messaging, onMessage } from 'firebase/messaging';
import { API_FACTORY, HttpClient, useAuthenticationContext, useEnvConfig, useFlightroomsContext } from 'shared';

import { WebNotificationType } from './open-web-notification/web-notification.types';

export const useFirebaseNotifications = () => {
  const {
    firebaseApiKey,
    firebaseAppId,
    firebaseAuthDomain,
    firebaseMessagingSenderId,
    firebaseProjectId,
    firebaseStorageBucket,
  } = useEnvConfig();
  const { user } = useAuthenticationContext();
  const { selectedFlightroom } = useFlightroomsContext();
  const [messaging, setMessaging] = useState<Messaging>();
  const [serviceWorker, setServiceWorker] = useState<ServiceWorkerRegistration>();

  const firebaseConfig = useMemo(
    () => ({
      apiKey: firebaseApiKey || '',
      appId: firebaseAppId || '',
      authDomain: firebaseAuthDomain || '',
      messagingSenderId: firebaseMessagingSenderId || '',
      projectId: firebaseProjectId || '',
      storageBucket: firebaseStorageBucket || '',
    }),
    [firebaseApiKey, firebaseAppId, firebaseAuthDomain, firebaseMessagingSenderId, firebaseProjectId, firebaseStorageBucket],
  );

  const getFirebaseToken = useCallback(async () => {
    const firebaseApp = initializeApp(firebaseConfig);
    const messaging = getMessaging(firebaseApp);
    setMessaging(messaging);

    const getOrRegisterServiceWorker = () => {
      if ('serviceWorker' in navigator && typeof window.navigator.serviceWorker !== 'undefined') {
        return window.navigator.serviceWorker.getRegistration('/firebase-push-notification-scope').then(serviceWorker => {
          if (serviceWorker) {
            serviceWorker.update();
            return serviceWorker;
          }

          return window.navigator.serviceWorker.register(`/firebase-messaging-sw.js?${new URLSearchParams(firebaseConfig)}`, {
            scope: '/firebase-push-notification-scope',
          });
        });
      }
    };

    if (messaging) {
      try {
        const serviceWorkerRegistration = await getOrRegisterServiceWorker();
        setServiceWorker(serviceWorkerRegistration);
        const token = await getToken(messaging, {
          serviceWorkerRegistration,
        });

        HttpClient.post(API_FACTORY.v1('users/fcm-token'), { fcmToken: token });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('An error occurred while retrieving token. ', error);
      }
    }
  }, [firebaseConfig]);

  useEffect(() => {
    if (user?.externalId) {
      getFirebaseToken();
    }
  }, [getFirebaseToken, user?.externalId]);

  useEffect(() => {
    if (messaging && serviceWorker) {
      onMessage(messaging, payload => {
        const roomId = payload.data?.link.split('/').at(-1);
        if (roomId !== selectedFlightroom?.externalId) {
          serviceWorker.showNotification(payload.notification?.title || '', {
            body: payload.notification?.body || '',
            data: {
              url: `/open-notification/${WebNotificationType.OPEN_FLIGHTROOM}/${roomId}`,
            },
            icon: '/favicon.ico',
            silent: false,
            tag: payload?.messageId,
          });
        }
      });
    }
  }, [selectedFlightroom, messaging, serviceWorker]);
};
