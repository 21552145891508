import React from 'react';
import { Icon, Text } from '@aviobook/_shared';
import { IconName } from 'assets';
import { COLORS } from 'styles';
import { RoomRoleTitle } from 'types';

import './profileHeader.scss';

type Props = {
  firstName?: string;
  iconName: IconName;
  jobTitle: string;
  lastName?: string;
  roomRoleTitle?: RoomRoleTitle;
};

export const ProfileHeader = ({ firstName, iconName, jobTitle, lastName }: Props) => {
  return (
    <header className="profile-section-top-container">
      <div className="profile-section-top-body">
        <div className="profile-icon-container">
          <Icon colorName={COLORS.yuma.$01} name={iconName} size={5} />
        </div>
        <Text color={COLORS.zulu.$08} size="MD" testID={'profile.username'} weight="semiBold">
          {lastName ? `${firstName} ${lastName}` : firstName}
        </Text>
        <Text color={COLORS.romeo.$07} size="MD" testID={'profile.jobTitle'} uppercase weight="bold">
          {jobTitle}
        </Text>
      </div>
    </header>
  );
};
