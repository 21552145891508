import React, { FC, useCallback, useMemo } from 'react';
import { Icon, Text, UnreadDot } from '@aviobook/_shared';
import { IconName } from 'assets';
import clsx from 'clsx';
import { DATE_TIME_FORMATS, formatISOString, formatTime, isAfterDateTime, useAuthenticatedUser } from 'shared';
import { COLORS } from 'styles';
import { FlightRoom, FlightRoomUserStatus } from 'types';

import './flightroomItem.scss';

type Props = {
  displayAccessState?: boolean;
  displayJoinIcon?: boolean;
  displayUnreadCount?: boolean;
  flightroom: FlightRoom;
  hasUnreadMentions?: boolean;
  isSelected?: boolean;
  onClick: (flightroom: FlightRoom) => void;
  showEstimatedTimes?: boolean;
  unreadCount?: number;
};

const getIconName = (
  isActiveMemberOfRoom: boolean,
  canRequestAccess: boolean,
  canJoinFlightRooms: boolean,
  hasPendingAccessRequest?: boolean,
  displayAccessState?: boolean,
): IconName | null => {
  if ((displayAccessState && hasPendingAccessRequest) || isActiveMemberOfRoom) {
    return 'circularCheckmarkDisabled';
  }
  if (canRequestAccess || canJoinFlightRooms) {
    return 'circularPlusFilledRegular';
  }
  return null;
};

export const FlightroomItem: FC<Props> = ({
  displayAccessState,
  displayJoinIcon,
  displayUnreadCount,
  flightroom,
  hasUnreadMentions,
  isSelected,
  onClick,
  showEstimatedTimes,
  unreadCount,
}) => {
  const { canJoinFlightRooms, canRequestAccess, hasGrantAccess, hasPendingAccessRequest, isActiveMemberOfRoom } =
    useAuthenticatedUser();

  const flightroomItemClasses = useMemo(
    () =>
      clsx('flightroom-container', {
        disabled: hasPendingAccessRequest(flightroom) && !canJoinFlightRooms,
        selected: isSelected,
      }),
    [hasPendingAccessRequest, canJoinFlightRooms, isSelected, flightroom],
  );

  const renderEstimatedOrActualTime = (scheduled: string, estimated?: string, actual?: string, alignRight?: boolean) => {
    if (!showEstimatedTimes || (!estimated && !actual)) {
      return null;
    }

    const time = actual || estimated;
    const isDelayed = time && isAfterDateTime(time, scheduled);

    return (
      <Text
        className={clsx({
          actual: !!actual,
          delay: isDelayed,
          'left-align': !alignRight,
          'right-align': alignRight,
        })}
        size="SM"
        weight="bold"
      >
        {time ? formatTime(time) || '' : ''}
      </Text>
    );
  };

  const handleClick = useCallback(() => {
    onClick(flightroom);
  }, [onClick, flightroom]);

  const notifications = useMemo(() => {
    let count = unreadCount || 0;
    if (hasGrantAccess && isActiveMemberOfRoom(flightroom)) {
      count += (flightroom?.users || []).filter(({ status }) => status === FlightRoomUserStatus.PENDING).length;
    }
    return count;
  }, [flightroom, hasGrantAccess, isActiveMemberOfRoom, unreadCount]);

  const iconName = useMemo(() => {
    return getIconName(
      isActiveMemberOfRoom(flightroom),
      canRequestAccess,
      canJoinFlightRooms,
      displayAccessState,
      hasPendingAccessRequest(flightroom),
    );
  }, [canJoinFlightRooms, canRequestAccess, displayAccessState, flightroom, hasPendingAccessRequest, isActiveMemberOfRoom]);

  return (
    <article className={flightroomItemClasses} onClick={handleClick}>
      <header className="header-container">
        <div className="header-content">
          <Text size="MD" weight="bold">
            {flightroom.flightNumber}
          </Text>
          <Text className="aircraft-id">{flightroom.aircraft}</Text>
        </div>
        <div className="header-right-content">
          {isActiveMemberOfRoom(flightroom) && hasUnreadMentions && !isSelected && <Icon name="mention" size={2} />}
          {displayAccessState && hasPendingAccessRequest(flightroom) && (
            <Icon
              colorName={isSelected ? COLORS.yuma.$01 : COLORS.zulu.$08}
              name="securityLockOutline"
              size={2.4}
              testId="flightroom-item-pending-request-icon"
            />
          )}
          {isActiveMemberOfRoom(flightroom) && displayUnreadCount && !isSelected && notifications > 0 && (
            <UnreadDot amount={notifications} size="large" testId="flightroom-item-notifications" />
          )}
          {displayJoinIcon && iconName && <Icon name={iconName} size={2.5} />}
        </div>
      </header>
      <section className="body">
        <div className="departure-airport">
          {flightroom.departure.airport.icao ? <Text className="airport-name">{flightroom.departure.airport.icao}</Text> : null}
          {flightroom.departure.airport.iata ? <Text className="airport-name">{flightroom.departure.airport.iata}</Text> : null}
        </div>
        <div className="center">
          <div className="time-row">
            <Text size="SM" weight="semiBold">
              {formatTime(flightroom.schedule.std) || ''}
            </Text>
            <div className="icon-wrapper">
              <div className="icon-seperator left"></div>
              <Icon name="aircraftPlaneRightFilled" size={1.6} />
              <div className="icon-seperator right"></div>
            </div>
            <Text size="SM" weight="semiBold">
              {formatTime(flightroom.schedule.sta) || ''}
            </Text>
          </div>
          <div className="time-row">
            <div className="delay-value">
              {renderEstimatedOrActualTime(flightroom.schedule.std, flightroom.schedule.etd, flightroom.schedule.atd)}
            </div>
            <Text className="date" size="SM" weight="semiBold">
              {formatISOString(flightroom.schedule.std, DATE_TIME_FORMATS.SHORT_DATE) || ''}
            </Text>
            <div className="delay-value">
              {renderEstimatedOrActualTime(flightroom.schedule.sta, flightroom.schedule.eta, flightroom.schedule.ata, true)}
            </div>
          </div>
        </div>
        <div className="arrival-airport">
          {flightroom.destination.airport.icao ? (
            <Text className="airport-name">{flightroom.destination.airport.icao}</Text>
          ) : null}
          {flightroom.destination.airport.iata ? (
            <Text className="airport-name">{flightroom.destination.airport.iata}</Text>
          ) : null}
        </div>
      </section>
    </article>
  );
};
