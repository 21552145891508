import { Iso8601DateString } from '../datetime';
import { Iata } from '../iata';
import { ConnectivityStatusV1 } from './connectivityStatusV1';
import { JobTitleV1 } from './jobTitleV1';

export enum UserSystemRole {
  ADMIN = 'ADMIN',
  SERVICE = 'SERVICE',
  USER = 'USER',
}

export enum UserStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export type UserV1 = {
  active?: boolean;
  archived?: boolean;
  connectivityStatus?: ConnectivityStatusV1;
  defaultStation?: Iata;
  email: string;
  externalId: string;
  firstName: string;
  id: string;
  info?: string;
  jobTitle: JobTitleV1;
  lastDisconnectionDate: Iso8601DateString;
  lastName: string;
  middleName?: string;
  phoneNumber?: string;
  preferredName?: string;
  rank?: string;
  roles?: string[];
  systemRole: UserSystemRole;
};
