import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Response } from '@aviobook/_hooks/useForm';
import { Checkbox, Text } from '@aviobook/_shared';
import { FlexComponent } from '@aviobook/cms/_shared';
import { createColumnHelper, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { isEmptyArray } from 'shared';
import { COLORS } from 'styles';
import { Keyword, SmartAction } from 'types';

type Props = {
  form: Response<SmartAction, SmartAction>;
};

export const useKeywordTable = ({ form }: Props) => {
  const { t } = useTranslation();
  const columnHelper = createColumnHelper<Keyword>();
  const [selectedKeywords, setSelectedKeywords] = useState<string[]>([]);

  const checkRow = (text: string, value: boolean) => {
    if (value) {
      setSelectedKeywords([...selectedKeywords, text]);
    } else {
      setSelectedKeywords(selectedKeywords.filter(item => item !== text));
    }
  };

  const isCheckedAll = () => {
    return form.values.keywords?.every(item => selectedKeywords.find(i => i === item.keyword));
  };
  const setSelectAll = (checked: boolean) => {
    let updatedItems: string[] = [];
    if (checked) {
      updatedItems = form.values.keywords.map(item => item.keyword);
    }
    setSelectedKeywords(updatedItems);
  };

  const isRowSelected = (keyword: string): boolean => {
    return !!selectedKeywords.find(item => item === keyword);
  };

  const data = useMemo(() => {
    return form.values?.keywords.sort((a, b) => a.keyword.localeCompare(b.keyword));
  }, [form.values?.keywords]);

  const columns = [
    columnHelper.accessor('keyword', {
      cell: info => (
        <FlexComponent flexDirection="row">
          <Checkbox
            checked={isRowSelected(info.row.original.keyword)}
            name="keyword"
            onChange={value => checkRow(info.row.original.keyword, value)}
          />
          <Text as="span" color={COLORS.zulu.$06} size="MD" width="partial-width">
            {info.getValue()}
          </Text>
        </FlexComponent>
      ),
      header: () => (
        <FlexComponent alignItems="center" backgroundColor="transparent" flexDirection="row">
          <Checkbox checked={isCheckedAll()} name="keywords" onChange={setSelectAll} />
          <Text as="span" color={COLORS.zulu.$08} size="XS" weight="bold" width="partial-width">
            {t('SMART_ACTION_FORM_KEYWORDS_TABLE_HEADER')}
          </Text>
        </FlexComponent>
      ),
      id: 'KEYWORDS',
      size: 10,
    }),
    columnHelper.accessor('description', {
      cell: info => (
        <FlexComponent flexDirection="row">
          <Text as="span" color={COLORS.zulu.$06} size="MD">
            {info.getValue()}
          </Text>
        </FlexComponent>
      ),
      header: t('SMART_ACTION_FORM_KEYWORDS_TABLE_DESCRIPTION_HEADER'),
      id: 'Description',
    }),
  ];

  const table = useReactTable({
    columns,
    data: data ?? [],
    getCoreRowModel: getCoreRowModel(),
  });
  return {
    deleteKeyword: () => {
      const updatedKeywords = form.values.keywords.filter(item => !selectedKeywords.includes(item.keyword));
      form.setAttribute(updatedKeywords, 'keywords');
      setSelectedKeywords([]);
    },
    isEmpty: isEmptyArray(form.values?.keywords),
    selectedKeywords,
    table,
  };
};
