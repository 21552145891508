import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useForm } from '@aviobook/_hooks/';
import { FormValidationErrors } from '@aviobook/_hooks/useForm';
import { Spinner } from '@aviobook/_shared';
import { FormValidator, PermissionsUtil, useAuthenticationContext } from 'shared';
import { SmartAction, SmartActionPermissions } from 'types';

import { useSmartAction } from '../_queries/useGetSmartAction';
import { useUpdateSmartAction } from '../_queries/useUpdateSmartAction';
import { SmartActionForm } from './SmartActionForm';

const validateForm = (values: SmartAction): FormValidationErrors<SmartAction> => {
  const validator = new FormValidator();

  return {
    name: validator.required(values.name),
    url: validator.required(values.url),
  };
};

export const UpdateSmartAction = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const id = searchParams.get('id') || '';

  const { data, isLoading: isLoadingSmartAction } = useSmartAction(id);

  const { user } = useAuthenticationContext();

  const { isLoading, mutate } = useUpdateSmartAction({
    onSuccess: () => {
      return navigate('/admin/connect');
    },
  });

  const permissions = new PermissionsUtil(user);

  const form = useForm<SmartAction>({
    // @ts-expect-error when data is non existent, it will early return and hook will not be used.
    // Cannot place hook after early return since that's not allowed to use it conditionally.
    initialForm: data,
    submitForm: values => mutate(values),
    validateForm: values => validateForm(values),
  });

  if (isLoading || isLoadingSmartAction || !data) {
    return <Spinner>{t('SHARED.LOADING_APPLICATION')}</Spinner>;
  }

  return (
    <main className="smart-action-container">
      {/* @ts-expect-error useForm */}
      <SmartActionForm canSave={permissions.hasPermission(SmartActionPermissions.UPDATE)} form={form} />
    </main>
  );
};
