import { createContext, FC, ReactNode, useContext, useEffect, useReducer, useState } from 'react';

import { MessageUnion, sortMessagesByDate, useGetMessages } from '../../hooks';
import { useSocket } from '../SocketContext';
import { chatMessagesReducer } from './chatMessages.reducer';
import { ChatMessagesActionType } from './chatMessagesAction.enum';
import { ChatMessagesContextType } from './chatMessagesContext.type';
import { useChatMessageSocketEvents } from './hooks/useChatMessageSocketEvents';
import { useSendChatMessage } from './hooks/useSendChatMessage';

type Props = {
  children: ReactNode;
  flightroomId: string;
};

export const ChatMessagesContext = createContext<ChatMessagesContextType>({
  chat: [],
  error: null,
  fetchOlderMessages: () => {},
  fetchingError: null,
  hasOlderMessages: false,
  isFetching: false,
  remove: () => {},
  retry: () => {},
  send: () => {},
});

export const useChatMessagesContext = () => {
  const context = useContext(ChatMessagesContext);
  if (!context) {
    throw new Error('useChatMessagesContext must be used within a ChatMessagesProvider');
  }
  return context;
};

export const ChatMessagesContextProvider: FC<Props> = ({ children, flightroomId }) => {
  const [state, dispatch] = useReducer(chatMessagesReducer, { error: null, messages: new Map() });
  const { socket } = useSocket();
  const [chat, setChat] = useState<MessageUnion[]>([]);
  const {
    error: fetchingError,
    fetchNextPage: fetchOlderMessages,
    hasNextPage: hasOlderMessages,
    isFetching,
    messages: newlyFetchedMessages,
  } = useGetMessages(flightroomId, () => {});

  useChatMessageSocketEvents(socket, dispatch, flightroomId);

  const { remove, retry, send } = useSendChatMessage(dispatch, flightroomId);

  useEffect(() => {
    const sortedChat = Array.from(state.messages.values()).sort(sortMessagesByDate);
    setChat(sortedChat);
  }, [state.messages]);

  useEffect(() => {
    if (newlyFetchedMessages) {
      dispatch({ payload: newlyFetchedMessages, type: ChatMessagesActionType.ADD_MESSAGES });
    }
  }, [newlyFetchedMessages]);

  return (
    <ChatMessagesContext.Provider
      value={{
        chat,
        error: state.error,
        fetchOlderMessages,
        fetchingError,
        hasOlderMessages,
        isFetching,
        remove,
        retry,
        send,
      }}
    >
      {children}
    </ChatMessagesContext.Provider>
  );
};
