export const getArrayOfTextSplitByMention = (text: string, mentions: string[]): string[] => {
  if (!mentions.length) {
    return [text];
  }
  const mentionsPattern = mentions.map(role => `@${role.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&')}`).join('|');

  const mentionRegex = new RegExp(`(${mentionsPattern})\\s*`, 'g');
  return text?.split(mentionRegex);
};

export const getMentionInProgressValue = (value: string): string => {
  if (value.startsWith('@')) {
    return value.substring(1);
  } else {
    return value;
  }
};

export const getMentionInProgress = (message: string, pattern: string, cursorPosition: number): string => {
  const isMentionInProgress = (word: string) => {
    return word.includes('@') && !new RegExp(pattern).test(word);
  };

  if (message) {
    const words = message.split(' ');
    const lastWord = words.at(-1);

    const currentWord = words.find(word => {
      return cursorPosition >= message.indexOf(word) && cursorPosition <= message.indexOf(word) + word.length;
    });

    if (currentWord && isMentionInProgress(currentWord)) {
      return currentWord;
    }

    if (lastWord && isMentionInProgress(lastWord)) {
      return lastWord;
    }
  }

  return '';
};
