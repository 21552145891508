import { AuthenticatedUser, CombiningTranslationDict, ConnectPermission } from 'types';

//Using custom reducer logic since every and some give unexpected true's for empty arrays
export class PermissionsUtil {
  private _haystack: AuthenticatedUser['permissions'];

  private _andStack: ConnectPermission[] = [];

  private _orStack: ConnectPermission[] = [];

  constructor(user?: AuthenticatedUser | null) {
    this._haystack = user?.permissions || [];
  }

  private reduceAnd(): boolean {
    if (this._andStack.length === 0) {
      return false;
    }

    return this._andStack.reduce((prev, current) => {
      if (!this._haystack.includes(current)) {
        return false;
      }

      return prev;
    }, true);
  }

  private reduceOr(): boolean {
    if (this._orStack.length === 0) {
      return false;
    }

    return this._orStack.reduce((prev, current) => {
      if (this._haystack.includes(current)) {
        return true;
      }

      return prev;
    }, false);
  }

  hasPermission(input: ConnectPermission): boolean {
    return this.hasPermissions([input]);
  }

  hasPermissions(
    input?: ConnectPermission | ConnectPermission[],
    type: CombiningTranslationDict = CombiningTranslationDict.AND,
  ): boolean {
    if (this._haystack.length === 0) {
      return false;
    }

    if (input) {
      return ([] as ConnectPermission[]).concat(input)[type](n => this._haystack.includes(n));
    }

    if (this._andStack.length === 0 && this._orStack.length === 0) {
      return false;
    }

    return this.reduceAnd() || this.reduceOr();
  }

  and(input: ConnectPermission | ConnectPermission[]): PermissionsUtil {
    this._andStack = this._andStack.concat(input);

    return this;
  }

  or(input: ConnectPermission | ConnectPermission[]): PermissionsUtil {
    this._orStack = this._orStack.concat(input);

    return this;
  }
}
