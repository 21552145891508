import { AppConfig } from '../../context';
import { AvioHeaders } from './headers';

export const getHeaders = (appConfig: AppConfig): AvioHeaders => {
  const headers = {
    'Avio-App-Account': appConfig.account,
    'Avio-App-Type': appConfig.type,
    'Avio-App-Version': appConfig.version,
    'Avio-Device-Id': appConfig.deviceId,
  };

  if (appConfig.installBundleId) {
    return { ...headers, 'Avio-Install-Bundle-Id': appConfig.installBundleId };
  }

  return headers;
};
