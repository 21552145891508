import * as React from "react";
const chevronDown: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.321 8.765a1.003 1.003 0 0 1 1.413.055.992.992 0 0 1-.055 1.407l-6.527 6.008a1.003 1.003 0 0 1-1.361-.003l-6.47-6.008a.992.992 0 0 1-.048-1.408 1.003 1.003 0 0 1 1.413-.048l5.79 5.377 5.845-5.38z"
      fillRule="evenodd"
      clipRule="evenodd"
    />
  </svg>
);
export default chevronDown;
