import { useTranslation } from 'react-i18next';
import { toastError, toastSuccess } from '@aviobook/Toastify';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { HttpClient } from 'shared';
import { API_FACTORY } from 'shared/src/utils/factories/api.factory';
import { ApiError, QueryKeys, Role } from 'types';

const role = (values: Role): Promise<Role> =>
  HttpClient.patch(API_FACTORY.v1(`admin/roles/${values.id}`), {
    displayName: values.displayName,
    isTaggable: values.isTaggable,
    permissions: values.permissions,
    quickReplies: values.quickReplies,
  });

export const useUpdateRole = (onSuccess?: (data: Role) => void) => {
  const queryClient = useQueryClient();

  const { t } = useTranslation();

  return useMutation<Role, ApiError, Role>([QueryKeys.ROLE], role, {
    onError: () => {
      toastError(t('TOAST_ERROR'));
    },
    onSuccess: data => {
      queryClient.invalidateQueries([QueryKeys.AUTHENTICATE]);
      queryClient.invalidateQueries([QueryKeys.ROLES]);
      queryClient.invalidateQueries([QueryKeys.ROLE, data.id]);

      toastSuccess(t('TOAST_SUCCESS'));

      if (typeof onSuccess === 'function') {
        onSuccess(data);
      }
    },
  });
};
