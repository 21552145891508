import { FC, useEffect, useRef, useState } from 'react';
import { Route, Routes, useSearchParams } from 'react-router-dom';
import { useFlightRoomFilter } from '@aviobook/_hooks';
import { ChatWrapper } from '@aviobook/chat/ChatWrapper';
import { Filters } from '@aviobook/filters/Filters';
import { useFirebaseNotifications } from '@aviobook/FirebaseConfig';
import {
  isRoomCompleted,
  useAuthenticatedUser,
  useFlightroomsContext,
  useFlightroomsUnreadCount,
  useNotificationSound,
} from 'shared';
import { sendRoomCloseEvent, sendRoomOpenEvent } from 'shared/src/sockets/socket';
import { FlightArrivalStatus, FlightRoom } from 'types';

import { FlightRoomList } from './_components/FlightRoomList';
import { FlightRoomsHeaderComponent } from './_components/FlightRoomsHeaderComponent/FlightRoomsHeaderComponent';
import { SearchedFlightRoomList } from './_components/SearchedFlightRoomList';
import { FlightroomDashboardTabs } from './flightroomDashboardTabs';

import './flightrooms.scss';

export const Flightrooms: FC = () => {
  useFirebaseNotifications();
  useNotificationSound();
  const { isActiveMemberOfRoom } = useAuthenticatedUser();
  const [searchParams, setSearchParams] = useSearchParams();
  const { activeRoomsWithUnreadCounts, completedRoomsWithUnreadCounts, resetUnreadCountsForRoom } =
    useFlightroomsUnreadCount(true);

  const [searchQuery, setSearchQuery] = useState<string>('');
  const [isLoadingSelectedFlightroom, setIsLoadingSelectedFlightroom] = useState(false);
  const { addFlightroom, getRoomById, selectedFlightroom, setSelectedFlightroom } = useFlightroomsContext();
  const [tab, setTab] = useState<FlightroomDashboardTabs>(
    selectedFlightroom && isRoomCompleted(selectedFlightroom) ? FlightArrivalStatus.COMPLETED : FlightArrivalStatus.ACTIVE,
  );
  const prevSelectedFlightroomRef = useRef<FlightRoom | null>(null);
  const { flightRoomFilterState, handleFlightRoomFilterChange, handleFlightRoomFilterReset, handleFlightRoomFilterSubmit } =
    useFlightRoomFilter();

  useEffect(() => {
    const setRoom = async (id: FlightRoom['externalId']) => {
      setIsLoadingSelectedFlightroom(true);
      let room = await getRoomById(id);

      if (!room || !isActiveMemberOfRoom(room)) {
        setSearchQuery('');
        setSearchParams();
        setSelectedFlightroom(null);
        setIsLoadingSelectedFlightroom(false);
        return;
      }

      setTab(isRoomCompleted(room) ? FlightArrivalStatus.COMPLETED : FlightArrivalStatus.ACTIVE);
      resetUnreadCountsForRoom(room.externalId);
      room = { ...room, hasUnreadMentions: false };
      setSelectedFlightroom(room);
      addFlightroom(room);
      setIsLoadingSelectedFlightroom(false);
    };

    const roomId = searchParams.get('room');
    if (roomId && roomId !== selectedFlightroom?.externalId) {
      setRoom(roomId);
    }
  }, [
    addFlightroom,
    getRoomById,
    isActiveMemberOfRoom,
    searchParams,
    selectedFlightroom?.externalId,
    setSelectedFlightroom,
    setTab,
    setSearchQuery,
    resetUnreadCountsForRoom,
    setSearchParams,
  ]);

  const flightrooms = tab !== FlightArrivalStatus.COMPLETED ? activeRoomsWithUnreadCounts : completedRoomsWithUnreadCounts;

  const handleTabChange = (newTab: FlightroomDashboardTabs) => {
    setTab(newTab);
  };

  const handleFlightroomClick = (room: FlightRoom) => {
    if (room.externalId !== selectedFlightroom?.externalId) {
      setSearchParams({ room: room.externalId });
    }
  };

  useEffect(() => {
    const prevFlightroom = prevSelectedFlightroomRef.current;
    prevSelectedFlightroomRef.current = selectedFlightroom || null;

    // No current or previous flightroom
    if (!selectedFlightroom && !prevFlightroom) {
      return;
    }

    // Current flightroom but no previous flightroom
    if (!prevFlightroom && selectedFlightroom) {
      resetUnreadCountsForRoom(selectedFlightroom.externalId);
      sendRoomOpenEvent(selectedFlightroom.externalId);
      return;
    }

    // Previous flightroom but no current flightroom
    if (prevFlightroom && !selectedFlightroom) {
      resetUnreadCountsForRoom(prevFlightroom?.externalId);
      sendRoomCloseEvent();
      return;
    }

    // Different flightrooms
    if (prevFlightroom?.externalId !== selectedFlightroom?.externalId) {
      if (prevFlightroom) {
        resetUnreadCountsForRoom(prevFlightroom?.externalId);
      }
      if (selectedFlightroom) {
        resetUnreadCountsForRoom(selectedFlightroom?.externalId);
      }
      sendRoomCloseEvent();
      // TODO check if this can be done in the same if as above
      if (selectedFlightroom) {
        sendRoomOpenEvent(selectedFlightroom?.externalId);
      }
    }
  }, [resetUnreadCountsForRoom, selectedFlightroom]);

  return (
    <>
      <aside className="flightrooms-container">
        <FlightRoomsHeaderComponent
          searchQuery={searchQuery}
          selectedFlightroomTab={tab}
          setSearchQuery={setSearchQuery}
          setSelectedFlightroomTab={handleTabChange}
        />
        {searchQuery ? (
          <SearchedFlightRoomList resetSearchQuery={() => setSearchQuery('')} searchQuery={searchQuery} />
        ) : (
          <FlightRoomList flightrooms={flightrooms} onFlightroomClick={handleFlightroomClick} selectedFlightroomTab={tab} />
        )}
      </aside>
      <div className="flightrooms-info">
        <Filters
          flightRoomFilterState={flightRoomFilterState}
          handleFlightRoomFilterChange={handleFlightRoomFilterChange}
          handleFlightRoomFilterReset={handleFlightRoomFilterReset}
          handleFlightRoomFilterSubmit={handleFlightRoomFilterSubmit}
        />
        <Routes>
          <Route element={<ChatWrapper isLoading={isLoadingSelectedFlightroom} />} path="*"></Route>
        </Routes>
      </div>
    </>
  );
};
