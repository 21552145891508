import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { COLORS } from 'styles';
import { LabelValue } from 'types';

import { Text } from '../../_shared';

import '../about.scss';

export const InfoItem: FC<LabelValue> = ({ label, value }) => {
  const { t } = useTranslation();

  return (
    <li>
      <Text color={COLORS.zulu.$08} size="SM" weight="semiBold">
        {t(`ABOUT.${label}`)}
      </Text>
      <Text className="about-version__info" color={COLORS.zulu.$08} size="SM">
        {value}
      </Text>
    </li>
  );
};
