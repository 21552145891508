import { FlightRoom, FlightRoomUser, RoomRoleTitle } from 'types';

import { DateTime } from '../date-time/lib/avio-date-time.model';
import { parseDate } from '../date-time/lib/avio-date-time.util';

const ROLE_ORDERS: RoomRoleTitle[] = [
  RoomRoleTitle.COMM,
  RoomRoleTitle.COPILOT,
  RoomRoleTitle.SCCM,
  RoomRoleTitle.CCM,
  RoomRoleTitle.ACM,
  RoomRoleTitle.GH,
  RoomRoleTitle.DEADHEAD,
  RoomRoleTitle.UNKNOWN,
];

const formatAirport = (icao: string | null | undefined, iata: string | null | undefined): string => {
  if (icao && iata) {
    return `${icao}-${iata}`;
  }
  return icao || iata || '–';
};

export const getFlightroomAirportNames = (
  flightroom?: FlightRoom,
): {
  arrivalAirport: string;
  departureAirport: string;
} => {
  if (!flightroom) {
    return {
      arrivalAirport: '',
      departureAirport: '',
    };
  }
  const departureAirport = formatAirport(flightroom.departure.airport.icao, flightroom.departure.airport.iata);
  const arrivalAirport = formatAirport(flightroom.destination.airport.iata, flightroom.destination.airport.icao);
  return { arrivalAirport, departureAirport };
};

export const isRoomInRange = (room: FlightRoom) => isRoomActive(room) || isRoomCompleted(room);

const isRoomActive = (room: FlightRoom) => {
  const today = new Date();
  const yesterday = parseDate().subtract({ day: 1 }).value;
  const tomorrow = parseDate().add({ day: 1 }).value;

  const departureDate = parseDate(room.schedule.atd || room.schedule.etd || room.schedule.std);
  const arrivalDate = parseDate(room.schedule.ata || room.schedule.eta || room.schedule.sta);

  const isDeparted = departureDate.isBefore(today);
  const isArrivalGreaterOrEqualToday = arrivalDate.isSameOrAfter(today);
  const isDepartureWithinRange = departureDate.isBetween(yesterday, tomorrow);

  return (isDeparted && isArrivalGreaterOrEqualToday) || isDepartureWithinRange;
};

export const isRoomCompleted = (room: FlightRoom) => {
  return isCompleted(room.schedule.sta, room.schedule.eta, room.schedule.ata);
};

export const isCompleted = (sta: DateTime, eta?: DateTime, ata?: DateTime) => {
  const today = new Date();
  const yesterday = parseDate().subtract({ day: 1 }).value;
  const arrivalDate = parseDate(ata || eta || sta);
  return arrivalDate.isBetween(yesterday, today);
};

export const checkIsUserScheduledForRoom = (externalUserId: string, room: FlightRoom): boolean => {
  return room.users?.find(user => user.externalId === externalUserId)?.scheduled ?? false;
};

export const sortFlightroomUsersByRole = (u1: FlightRoomUser, u2: FlightRoomUser): number => {
  const a = ROLE_ORDERS.indexOf(u1.type.role);
  const b = ROLE_ORDERS.indexOf(u2.type.role);

  // Handle roles that are not in ROLE_ORDERS by placing them at the end
  if (a === -1) {
    return 1;
  }
  if (b === -1) {
    return -1;
  }

  return a - b;
};

export const sortFlightroomsByStd = (f1: FlightRoom, f2: FlightRoom): number => {
  const f1Std = new Date(f1.schedule.std).getTime();
  const f2Std = new Date(f2.schedule.std).getTime();

  return f1Std - f2Std;
};
