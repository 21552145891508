import { useCallback, useMemo } from 'react';
import { Division, FlightRoomUserStatus, Mention, MentionType, RoomRoleTitle } from 'types';

import { useFlightroomsContext, useUsersContext } from '../../context';
import { replaceJobTitlePlaceholders, UserV1Util } from '../../utils';
import { useGetExternalMentions } from './useGetExternalMentions';

export const useMentions = (mentionInProgress: string = '') => {
  const { selectedFlightroom: flightroom } = useFlightroomsContext();
  const { users } = useUsersContext();

  const { externalMentions } = useGetExternalMentions(mentionInProgress);

  const groupUsersByRole = (roleMap: Map<string, Mention>, role: string, userExternalId: string, type: MentionType) => {
    if (!roleMap.has(role)) {
      roleMap.set(role, {
        isExternal: false,
        label: role,
        type,
        users: [],
      });
    }
    roleMap.get(role)!.users.push(userExternalId);
  };

  const crewRoleMentionOptions = useMemo(() => {
    if (!flightroom?.users) {
      return [];
    }

    const roleMap = new Map<string, Mention>();

    const handleScheduledUser = (role: string, externalId: string) => {
      groupUsersByRole(roleMap, role, externalId, MentionType.CREW_ROLE);
    };

    const handleUnscheduledUser = (externalId: string) => {
      const user = users.find(u => u.externalId === externalId);
      const role = user?.jobTitle ? UserV1Util.getDivision(user.jobTitle).toUpperCase() : Division.UNKNOWN.toUpperCase();
      groupUsersByRole(roleMap, role, externalId, MentionType.CREW_ROLE);
    };

    flightroom.users.forEach(({ externalId, scheduled, status, type }) => {
      if (status !== FlightRoomUserStatus.ACTIVE) {
        return;
      }

      if (scheduled) {
        handleScheduledUser(replaceJobTitlePlaceholders(type.name || RoomRoleTitle.UNKNOWN), externalId);
      } else {
        handleUnscheduledUser(externalId);
      }
    });

    return Array.from(roleMap.values());
  }, [flightroom?.users, users]);

  const userRoleMentionOptions = useMemo(() => {
    if (!flightroom?.users) {
      return [];
    }

    const roleMap = new Map<string, Mention>();
    flightroom.users.forEach(({ externalId, status }) => {
      const user = users.find(u => u.externalId === externalId);
      if (status !== FlightRoomUserStatus.ACTIVE) {
        return;
      }

      user?.roles?.forEach(role => {
        if (role) {
          groupUsersByRole(roleMap, role, externalId, MentionType.USER_ROLE);
        }
      });
    });

    return Array.from(roleMap.values());
  }, [flightroom, users]);

  const crewNameMentionOptions = useMemo(() => {
    if (!flightroom?.users) {
      return [];
    }

    return flightroom.users.reduce<Mention[]>((acc, flightRoomUser) => {
      if (flightRoomUser.status !== FlightRoomUserStatus.ACTIVE) {
        return acc;
      }
      const user = users.find(({ externalId }) => externalId === flightRoomUser.externalId);
      if (user) {
        acc.push({
          isExternal: false,
          label: `${user.firstName} ${user.lastName}`,
          type: MentionType.USER_NAME,
          users: [user.externalId],
        });
      }
      return acc;
    }, []);
  }, [flightroom, users]);

  const mentionOptions = useMemo(
    () =>
      [...userRoleMentionOptions, ...crewRoleMentionOptions, ...crewNameMentionOptions].sort((a, b) =>
        a.label.localeCompare(b.label),
      ), // Sort alphabetically by label
    [userRoleMentionOptions, crewRoleMentionOptions, crewNameMentionOptions],
  );

  const filteredMentionOptions: Mention[] = useMemo(() => {
    return mentionOptions.filter(mention =>
      mention.label.toUpperCase().includes(mentionInProgress.toUpperCase().split('@')[1] || ''),
    );
  }, [mentionOptions, mentionInProgress]);

  const mentionsRegex = useMemo(() => {
    const options = [...mentionOptions, ...externalMentions];
    if (!options?.length) {
      return '';
    }
    return `@(${options
      .map(mention => `\\b${mention.label.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&')}\\b(?!\\w)`)
      .sort((a, b) => b.length - a.length) // Sort by length to match longest mentions first
      .join('|')})`;
  }, [mentionOptions, externalMentions]);

  const getUsedMentions = useCallback(
    (message: string = ''): Mention[] => {
      const foundMentions = (message?.match(new RegExp(mentionsRegex, 'gi')) || []) as string[];
      return mentionOptions.filter(mention => foundMentions.includes(`@${mention.label}`));
    },
    [mentionOptions, mentionsRegex],
  );

  return { externalMentions, filteredMentionOptions, getUsedMentions, mentionOptions, mentionsRegex };
};
