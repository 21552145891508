import { FC, FormEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Response } from '@aviobook/_hooks/useForm';
import { Checkbox, Icon, InputField } from '@aviobook/_shared';
import { Tooltip } from '@aviobook/_shared/tooltip/tooltip';
import { PageContentBlock, SaveButton } from '@aviobook/cms/_shared';
import { ReferenceType } from '@floating-ui/react';
import { COLORS } from 'styles';
import { Role } from 'types';

import './roleForm.scss';

type Props = {
  form: Response<Role, Role>;
  onSubmit: (event: FormEvent<HTMLFormElement>) => void;
};

export const RoleForm: FC<Props> = ({ form, onSubmit }) => {
  const { t } = useTranslation();
  const [tooltipAnchor, setTooltipAnchor] = useState<ReferenceType | null>(null);

  return (
    <>
      <Tooltip alignment="top-start" anchor={tooltipAnchor}>
        <slot name="content">
          <span>{t('USERS.TAG.TAGGABLE')}</span>
        </slot>
      </Tooltip>
      <form onSubmit={onSubmit}>
        <div className="role-form-top-container">
          <SaveButton isDisabled={!form.isDirty} />
        </div>
        <PageContentBlock title={t('ROLESFORM.TITLE.DETAILS')}>
          <div className="row">
            <div className="col-1">
              <InputField
                disabled
                label={t('ROLESFORM.LABEL.NAME')}
                name="airlineRole"
                onChange={form.setAttribute}
                required
                showInlineBottomError
                validation={form.validationErrors.airlineRole}
                value={form.values?.airlineRole || ''}
              />
            </div>
            <div className="col-1">
              <InputField
                label={t('ROLESFORM.LABEL.DISPLAY_NAME')}
                name="displayName"
                onChange={form.setAttribute}
                required
                showInlineBottomError
                validation={form.validationErrors.displayName}
                value={form.values?.displayName || ''}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-2 role-form-column-taggable">
              <div className="role-form-checkbox-taggable">
                <Checkbox
                  checked={form.values?.isTaggable}
                  className="set-label-style"
                  label={t('ROLESFORM.CHECKBOX.TEXT')}
                  name="airlineRole"
                  onChange={value => form.setAttribute(value, 'isTaggable')}
                />
                <Icon
                  className="ml-1"
                  colorName={COLORS.zulu.$08}
                  name="circularInfoOutline"
                  onHover={anchor => {
                    setTooltipAnchor(anchor.currentTarget);

                    return () => setTooltipAnchor(null);
                  }}
                />
              </div>
            </div>
          </div>
        </PageContentBlock>
      </form>
    </>
  );
};
