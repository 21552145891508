import React, { createContext, FC, ReactNode, useContext, useEffect, useRef, useState } from 'react';
import { AppState, Platform } from 'react-native';
import { QueryClient } from '@tanstack/react-query';
import { Socket } from 'socket.io-client';
import { IncomingSocketEvents, OutgoingSocketEvents } from 'types';

import { getSocket } from '../sockets/socket';
import { AppConfig, useEnvConfig } from './';

type SocketContext = {
  initializeSocket: (appConfig: AppConfig) => void;
  isConnected: boolean;
  socket: Socket<IncomingSocketEvents, OutgoingSocketEvents> | null;
};

const SocketContext = createContext<SocketContext>({
  initializeSocket: () => {},
  isConnected: false,
  socket: null,
});

export const useSocket = () => useContext(SocketContext);
type Props = {
  children: ReactNode;
  queryClient: QueryClient;
};

export const SocketContextProvider: FC<Props> = ({ children, queryClient }) => {
  const { connectServiceApiHost } = useEnvConfig();
  const [socket, setSocket] = useState<Socket | null>(null);
  const [isConnected, setIsConnected] = useState(socket?.connected ?? false);
  const appState = useRef(AppState.currentState);

  useEffect(() => {
    if (!socket) {
      return;
    }

    if (Platform.OS === 'android' || Platform.OS === 'ios') {
      const subscription = AppState.addEventListener('change', nextAppState => {
        if (appState.current.match(/inactive|background/) && nextAppState === 'active') {
          socket.connect();
        } else if (nextAppState === 'background') {
          socket.disconnect();
        }

        appState.current = nextAppState;
      });

      return () => {
        subscription?.remove();
      };
    }
  }, [socket]);

  useEffect(() => {
    if (!socket) {
      return;
    }

    socket.on('connect', () => {
      setIsConnected(true);
    });

    socket.on('disconnect', () => {
      setIsConnected(false);
      queryClient.invalidateQueries();
    });

    // Clean up event handlers when component is unmounted
    return () => {
      socket.off('connect');
      socket.off('disconnect');
    };
  }, [socket, queryClient]);

  const initializeSocket = (appConfig: AppConfig) => {
    setSocket(getSocket(connectServiceApiHost, appConfig));
  };

  return <SocketContext.Provider value={{ initializeSocket, isConnected, socket }}>{children}</SocketContext.Provider>;
};
