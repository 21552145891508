import { FC } from 'react';
import { Link } from 'react-router-dom';
import { Text } from '@aviobook/_shared';
import classnames from 'classnames';
import { COLORS } from 'styles';

import './breadcrumb.scss';

type Props = {
  disabled: boolean;
  name: string;
  url: string;
};

export const BreadCrumb: FC<Props> = ({ disabled, name, url }) => {
  return (
    <li className="nav-bar-li">
      <Link className={classnames(disabled ? 'disabled-link' : undefined)} to={url}>
        <Text as="span" color={COLORS.zulu.$08} size="XL" uppercase weight={disabled ? 'bold' : 'regular'}>
          {/* decodeURI is needed if you have a breadcrumb with spaces or special char */}
          {decodeURI(name)}
        </Text>
      </Link>
    </li>
  );
};
