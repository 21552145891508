import { FC, ReactNode } from 'react';
import classnames from 'classnames';

import { Icon } from '../icon/Icon';

import './errorMessage.scss';

type Props = {
  children: ReactNode;
  className?: string;
  isGlobal?: boolean;
  isVisible?: boolean;
};

export const ErrorMessage: FC<Props> = ({ children, className = '' }) => {
  return (
    <div className={classnames('error-message', className)}>
      <Icon name="exclamationHexagonFilled" />
      <span>{children}</span>
    </div>
  );
};
