import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLogout } from '@aviobook/_hooks';
import { getIconForOccupation } from '@aviobook/_utils';
import { About } from '@aviobook/about/About';
import { ProfileHeader } from '@aviobook/profile/components/ProfileHeader';
import { Settings } from '@aviobook/settings/Settings';
import { useAuthenticationContext, UserV1Util, useSocket } from 'shared';
import { COLORS } from 'styles';

import { Button, SideModal, SideModalHeader } from '../_shared';
import ConfirmationModal from '../modal/confirmation/ConfirmationModal';
import { ModalOpener } from '../modal/ModalOpener';
import { ProfileSection } from './components/ProfileSection';

import './profile.scss';

type Props = {
  isVisible: boolean;
  onClose: () => void;
};

type Screen = 'PROFILE' | 'ABOUT' | 'SETTINGS';

export const Profile: FC<Props> = ({ isVisible, onClose }) => {
  const { t } = useTranslation();

  const { user } = useAuthenticationContext();
  const { socket } = useSocket();

  const logout = useLogout();

  const { email, phoneNumber, rank } = user || {};
  const [screenToDisplay, setScreenToDisplay] = useState<Screen>('PROFILE');

  const onClickLogout = () => {
    ModalOpener.instance.open({
      render: () => (
        <ConfirmationModal
          confirmText={t('AUTH.LOGOUT')}
          content={t('AUTH.LOGOUT_ALERT_REDIRECT')}
          onConfirm={() => {
            socket?.disconnect();
            logout();
          }}
          title={t('AUTH.LOGOUT')}
        />
      ),
    });
  };

  const renderContent = () => {
    switch (screenToDisplay) {
      case 'ABOUT':
        return <About />;
      case 'SETTINGS':
        return <Settings />;
      case 'PROFILE':
      default:
        return (
          <>
            <ProfileHeader
              firstName={user?.username}
              iconName={getIconForOccupation(user?.jobTitle)}
              jobTitle={UserV1Util.getDivision(user?.jobTitle)}
            />
            <div className="profile-mid-bot-container">
              <section className="profile-section-mid-container">
                <ProfileSection icon="briefcase" title={t('PROFILE.JOB_TITLE')} value={rank} />
                <ProfileSection icon="phone" title={t('PROFILE.PHONE_NUMBER')} value={phoneNumber} />
                <ProfileSection icon="email" title={t('PROFILE.EMAIL')} value={email} />
              </section>
            </div>
          </>
        );
    }
  };

  const renderButtons = () => {
    if (screenToDisplay === 'PROFILE') {
      return (
        <>
          <Button className="profile-bottom-button" isOutline onClick={() => setScreenToDisplay('ABOUT')} theme="dark">
            {t('PROFILE.ABOUT')}
          </Button>
          <Button className="profile-bottom-button" onClick={onClickLogout}>
            {t('AUTH.LOGOUT')}
          </Button>
        </>
      );
    }

    return null;
  };

  const renderHeader = (): SideModalHeader => {
    switch (screenToDisplay) {
      case 'ABOUT':
        return {
          leftIcon: {
            iconName: 'arrowLeft',
            onClick: () => {
              setScreenToDisplay('PROFILE');
            },
          },
          title: {
            props: { uppercase: false },
            text: t('PROFILE.ABOUT'),
          },
        };
      case 'SETTINGS':
        return {
          leftIcon: {
            iconName: 'arrowLeft',
            onClick: () => {
              setScreenToDisplay('PROFILE');
            },
          },
          title: {
            props: { uppercase: false },
            text: t('SETTINGS.HEADER'),
          },
        };
      case 'PROFILE':
      default:
        return {
          backgroundColor: COLORS.victor.$03,
          leftIcon: {
            iconName: 'gear',
            onClick: () => {
              setScreenToDisplay('SETTINGS');
            },
          },
          rightIcon: {
            iconName: 'closeCross',
            onClick: onClose,
          },
          title: { props: { uppercase: false }, text: t('PROFILE.HEADER_TITLE') },
        };
    }
  };

  const handleBackgroundClick = () => {
    setScreenToDisplay('PROFILE');
    onClose();
  };
  return (
    <SideModal
      bottomButtons={renderButtons()}
      content={renderContent()}
      fullwidth={screenToDisplay !== 'SETTINGS'}
      header={renderHeader()}
      isVisible={isVisible}
      onBackgroundClick={handleBackgroundClick}
      placement={'left'}
    />
  );
};
