import { useEffect, useState } from 'react';
import { EnvConfig, HttpClient } from 'shared';

type EnvJson = {
  CONNECT_PORTAL_FIREBASE_API_KEY: string;
  CONNECT_PORTAL_FIREBASE_APP_ID: string;
  CONNECT_PORTAL_FIREBASE_AUTH_DOMAIN: string;
  CONNECT_PORTAL_FIREBASE_MESSAGING_SENDER_ID: string;
  CONNECT_PORTAL_FIREBASE_PROJECT_ID: string;
  CONNECT_PORTAL_FIREBASE_STORAGE_BUCKET: string;
  CONNECT_PORTAL_FIREBASE_VAPID_KEY: string;
  CONNECT_SERVICE_API_HOST: string;
  CONNECT_SERVICE_API_KEY: string;
  NODE_ENV: string;
  REACT_APP_VERSION_NR: string;
};

export const useSetupEnvConfig = () => {
  const [envConfig, setEnvConfig] = useState<EnvConfig | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    fetch('/environment_config.json')
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to fetch environment config');
        }
        return response.json();
      })
      .then((data: EnvJson) => {
        setEnvConfig({
          appVersionNumber: __REACT_APP_VERSION__,
          connectServiceApiHost: data.CONNECT_SERVICE_API_HOST,
          connectServiceApiKey: data.CONNECT_SERVICE_API_KEY,
          firebaseApiKey: data.CONNECT_PORTAL_FIREBASE_API_KEY,
          firebaseAppId: data.CONNECT_PORTAL_FIREBASE_APP_ID,
          firebaseAuthDomain: data.CONNECT_PORTAL_FIREBASE_AUTH_DOMAIN,
          firebaseMessagingSenderId: data.CONNECT_PORTAL_FIREBASE_MESSAGING_SENDER_ID,
          firebaseProjectId: data.CONNECT_PORTAL_FIREBASE_PROJECT_ID,
          firebaseStorageBucket: data.CONNECT_PORTAL_FIREBASE_STORAGE_BUCKET,
          firebaseVapidKey: data.CONNECT_PORTAL_FIREBASE_VAPID_KEY,
          installBundleId: '',
          nodeEnv: data.NODE_ENV,
        });
      })
      .catch(() => {
        setError('Failed to load environment configuration');
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (envConfig) {
      HttpClient.setConfig(envConfig);
      setLoading(false);
    }
  }, [envConfig]);

  return { envConfig, error, loading };
};
