export enum QueryKeys {
  ACCOUNT_SETTINGS = 'account-settings',
  AUTHENTICATE = 'authenticate',
  FLIGHTROOMS = 'flightrooms',
  GET_FLIGHTROOM = 'get_flightroom',
  GRANT_ACCESS = 'grant_access',
  JOIN_FLIGHTROOM = 'join_flightroom',
  MENTIONS = 'mentions',
  MESSAGES = 'messages',
  NOTIFICATIONS = 'notifications',
  QUICK_REPLIES = 'quickReplies',
  REQUEST_ACCESS = 'request_access',
  ROLE = 'role',
  ROLES = 'roles',
  SEARCH_FLIGHTROOM = 'search_flightroom',
  SETTINGS = 'settings',
  SMART_ACTION = 'smart_action',
  SMART_ACTIONS = 'smart_actions',
  UPLOAD_ATTACHMENT = 'upload_attachment',
  USERS = 'users',
  VERSION = 'version',
}
