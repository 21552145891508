import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';
import { Spinner } from '@aviobook/_shared';
import { PageContent } from '@aviobook/cms/_shared';
import { Tab } from '@aviobook/cms/_shared/pageContent/PageContent';
import { useRole } from '@aviobook/cms/connect/_screens/roles/role/_hooks/useRole';
import { QuickRepliesPermissions, RolesPermissions } from 'types';

import { RoleDetails } from './_tabs/roleDetails/RoleDetails';
import { RolePermissions } from './_tabs/rolePermissions/RolePermissions';
import { RoleQuickRepliesComponent } from './_tabs/roleQuickReplies/RoleQuickReplies';

export const Role: FC = () => {
  const { t } = useTranslation();
  const { data, isError, isLoading } = useRole();

  if (isLoading) {
    return <Spinner>{t('SHARED.LOADING_APPLICATION')}</Spinner>;
  }

  if (isError) {
    return <Navigate to="/admin/connect/roles" />;
  }

  const tabs: Tab[] = [
    {
      label: t('TAB.ROLES.ROLE.DETAILS'),
      name: 'Roles',
      order: 0,
      permissions: [RolesPermissions.VIEW, RolesPermissions.UPDATE],
      screen: () => (data ? <RoleDetails data={data} isLoadingRole={isLoading} /> : <></>),
    },
    {
      label: t('TAB.ROLES.ROLE.PERMISSIONS'),
      name: 'Permissions',
      order: 1,
      permissions: [RolesPermissions.UPDATE, RolesPermissions.VIEW],
      screen: () => <RolePermissions />,
    },
    {
      label: t('QUICK_REPLIES.MULTI'),
      name: 'quickReplies',
      order: 2,
      permissions: [QuickRepliesPermissions.VIEW],
      screen: () => <RoleQuickRepliesComponent />,
    },
  ];

  return <PageContent navBarClassName="top-nav-bar" tabs={tabs} textSize="XL" />;
};
