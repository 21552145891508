import { FiqlGroupTypes } from '../groups';
import { FiqlNodeTypes } from '../nodes';
import BaseFiql from '../types/base.interface';
import FiqlGroupOperators from '../types/fiql-group-operators.enum';

export default abstract class BaseFiqlGroup<Op extends FiqlGroupOperators, T extends object = { [key: string]: unknown }>
  implements BaseFiql
{
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  protected operator: Op;
  protected args: (FiqlNodeTypes<Required<T>> | FiqlGroupTypes<Required<T>>)[];

  constructor(args: (FiqlNodeTypes<Required<T>> | FiqlGroupTypes<Required<T>>)[]) {
    this.args = args;
  }

  public parse(): string {
    return `(${this.args.map(node => node.parse()).join(this.operator)})`;
  }
}
