import React, { FC, MouseEvent } from 'react';
import { IconName } from 'assets';
import * as ICONS from 'assets/svg/generated';
import classnames from 'classnames';
import { ColorName } from 'styles';

import './icon.scss';

type Props = {
  buttonClassName?: string;
  buttonSize?: number;
  className?: string;
  clickable?: boolean;
  colorName?: ColorName;
  disabled?: boolean;
  id?: string;
  label?: string;
  name: IconName;
  onClick?: (event: MouseEvent) => void;
  onHover?: (event: MouseEvent) => CallableFunction;
  size?: number | null;
  testId?: string;
};

export const Icon: FC<Props> = ({
  buttonClassName,
  buttonSize,
  className = '',
  clickable,
  colorName,
  disabled,
  id,
  label,
  name,
  onClick,
  onHover,
  size = 2,
  testId,
  ...otherProps
}) => {
  const Svg = ICONS[name];

  if (!Svg) {
    return null;
  }

  const sizeObject = size ? { height: `${size}rem`, width: `${size}rem` } : {};
  const buttonSizeObject = buttonSize ? { height: `${buttonSize}rem`, width: `${buttonSize}rem` } : sizeObject;

  const handles: {
    onClick?: (value: MouseEvent) => void;
    onMouseEnter?: (value: MouseEvent) => void;
    onMouseLeave?: () => CallableFunction;
  } = {};

  if (onClick) {
    handles.onClick = onClick;
  }
  if (onHover) {
    handles.onMouseEnter = onHover;
    // @ts-expect-error TODO check if setting to undefined is safe
    handles.onMouseLeave = () => onHover({ currentTarget: null } as MouseEvent);
  }

  const isIconOnly = !clickable && Object.entries(handles).length === 0;

  const IconComponent = (test?: string) => {
    return (
      <i
        data-test={test}
        {...otherProps}
        className={classnames(['icon', name, isIconOnly && className, { 'custom-color': !!colorName }])}
        style={{ ...sizeObject, color: colorName }}
      >
        <Svg />
      </i>
    );
  };

  if (isIconOnly) {
    return IconComponent(testId);
  }

  return (
    <button
      aria-label={label}
      className={classnames(buttonClassName ? buttonClassName : 'plain', className)}
      data-test={testId}
      disabled={disabled}
      id={id}
      {...handles}
      style={buttonSizeObject}
      {...otherProps}
      type="button"
    >
      {IconComponent()}
    </button>
  );
};
