import { Option } from '@aviobook/_types/Option';

export const mapToOption = (value: string): Option => {
  return { label: value, value: value };
};

export const mapToOptions = (values: string[]): Option[] => {
  return values.map(mapToOption);
};

export const generateTimeOptions = (interval: number): Option[] => {
  const options: Option[] = [];
  for (let hour = 0; hour < 24; hour++) {
    for (let minute = 0; minute < 60; minute += interval) {
      const time = `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;
      options.push({ label: time, value: time });
    }
  }
  return options;
};
