import { AxiosError } from 'axios';
import { ApiError, PaginationV1 } from 'types';

import { HttpClient, Params } from '../http';

export class AutocompleteClient {
  static async getAutoComplete(route: string, searchValue = ''): Promise<string[]> {
    const queryParams = AutocompleteClient.prepareParams(searchValue);
    try {
      const response = await HttpClient.get<PaginationV1<string>>(route, queryParams);
      return response.items ?? [];
    } catch (error) {
      throw HttpClient.createApiError(error as AxiosError<ApiError>);
    }
  }

  private static prepareParams(searchValue: string): Params {
    let httpParams: Params = {};

    if (searchValue) {
      httpParams = { ...httpParams, searchValue };
    }

    return httpParams;
  }
}
