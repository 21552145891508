import { useEffect } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useAppConfigContext, useAuthenticationContext, useSocket } from 'shared';

export const useAppInitializer = () => {
  const { user } = useAuthenticationContext();
  const { appConfig } = useAppConfigContext();

  const { initializeSocket, socket } = useSocket();
  const queryClient = useQueryClient();

  useEffect(() => {
    const timer = setInterval(() => {
      // If the app becomes unstable or rerenders too forcibly, use .resetQueries()
      queryClient.invalidateQueries();
    }, 300000); // 5 minutes
    return () => clearInterval(timer);
  }, [queryClient]);

  useEffect(() => {
    if (user) {
      initializeSocket({ ...appConfig, account: user.account });
    }
  }, [user, initializeSocket, appConfig]);

  useEffect(() => {
    socket?.connect();

    return () => {
      socket?.disconnect();
    };
  }, [socket]);

  return { isAppLoaded: Boolean(user), isLoggedIn: Boolean(user) };
};
