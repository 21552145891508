import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { MentionOptionV1, QueryKeys } from 'types';

import { useFlightroomsContext } from '../../context';
import { API_FACTORY, getUrlWithQueryParams, HttpClient, isEmptyString } from '../../utils';
import { getMentionInProgressValue } from '../../utils/mentions/MentionUtils';
import { useDebounce } from '../useDebounce';
import { transformMentionOptionToMention } from './mentions.transformer';

type MentionQueryOptions = {
  displayName: string;
  room?: string;
};

const getMentions = (requestOptions: MentionQueryOptions): Promise<MentionOptionV1[]> => {
  if (isEmptyString(requestOptions.room)) {
    return Promise.reject(new Error('Room should not be empty value.'));
  }

  const url = getUrlWithQueryParams(API_FACTORY.v1('mentions/options'), {
    displayName: requestOptions.displayName,
    room: requestOptions.room,
  });
  return HttpClient.get(url);
};

export const useGetExternalMentions = (queryString: string) => {
  const { selectedFlightroom: flightroom } = useFlightroomsContext();
  const debouncedQuery = useDebounce(getMentionInProgressValue(queryString), 300);

  const { data = [] } = useQuery<MentionOptionV1[]>({
    enabled: queryString.length > 1,
    queryFn: () => {
      return getMentions({ displayName: debouncedQuery, room: flightroom?.externalId });
    },
    queryKey: [QueryKeys.MENTIONS, debouncedQuery],
  });

  const externalMentions = useMemo(() => {
    return data.map(transformMentionOptionToMention);
  }, [data]);

  return {
    externalMentions: externalMentions,
  };
};
