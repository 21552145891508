import React, { forwardRef, ReactElement, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InputToolbarProps } from 'react-native-gifted-chat';
import { useWindowDimensions } from '@aviobook/_hooks';
import { Icon } from '@aviobook/_shared';
import { useMentions } from 'shared';
import { getMentionInProgress } from 'shared/src/utils/mentions/MentionUtils';
import { COLORS } from 'styles';
import { GiftedMessage, Mention } from 'types';

import './mentionInput.scss';

type Props = InputToolbarProps<GiftedMessage> & {
  icon: ReactElement;
  message: string;
  sendDisabled: boolean;
  sendMessage: () => void;
  setMessage: (message: string) => void;
};

type RelevantMentionSection = {
  data: Mention[];
  title: string;
};

export type MentionInputRef = {
  sendText: (text: string) => void;
};

export const MentionInput = forwardRef<MentionInputRef, Props>(
  ({ icon, message, sendDisabled, sendMessage, setMessage }, ref) => {
    const [mentionInProgress, setMentionInProgress] = useState('');
    const [cursorPositionX, setCursorPositionX] = useState(0);
    const [cursorIndex, setCursorIndex] = useState(0);
    const [isInputFocused, setIsInputFocused] = useState(true);
    const [suggestionIndex, setSuggestionIndex] = useState(0);
    const { t } = useTranslation();

    const { windowHeight } = useWindowDimensions();

    const containerRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLDivElement>(null);
    const suggestionListRef = useRef<HTMLDivElement>(null);

    useImperativeHandle(ref, () => ({
      sendText: (text: string) => {
        const newMessage = insertText(text);
        const newTextInputIndex = cursorIndex + text.length + 1;
        setTextAndCursorPosition(newMessage, newTextInputIndex);
      },
    }));

    const { externalMentions, filteredMentionOptions, mentionsRegex } = useMentions(mentionInProgress.trim());

    useEffect(() => {
      inputRef.current?.focus();
    }, [inputRef]);

    useEffect(() => {
      const mention = getMentionInProgress(message, mentionsRegex, cursorIndex);
      setMentionInProgress(mention);
    }, [message, mentionsRegex, cursorIndex]);

    const handleClickOutside = useCallback((event: MouseEvent) => {
      if (
        suggestionListRef.current &&
        !suggestionListRef.current.contains(event.target as Node) &&
        inputRef.current &&
        !inputRef.current.contains(event.target as Node)
      ) {
        setIsInputFocused(false);
      }
    }, []);

    useEffect(() => {
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [handleClickOutside]);

    const makeMentionsBold = useCallback(
      (text: string) => {
        const pattern = new RegExp(mentionsRegex, 'g');
        return text.replace(pattern, match => {
          return `<span class="bold-text">${match}</span>`;
        });
      },
      [mentionsRegex],
    );

    const setTextAndCursorPosition = useCallback(
      (newText: string, cursorIndex: number) => {
        const editor = inputRef.current;
        if (!editor) {
          return;
        }

        editor.textContent = newText;
        editor.innerHTML = makeMentionsBold(newText);
        setMessage(newText);

        restoreCursorPosition(cursorIndex);
        setCursorIndex(cursorIndex);
      },
      [makeMentionsBold, setMessage],
    );

    useEffect(() => {
      // reset inner text value of input field when message is sent
      if (inputRef.current && !message) {
        setTextAndCursorPosition('', 0);
      }
    }, [message, setTextAndCursorPosition]);

    const suggestions: Mention[] = useMemo(() => {
      return [...filteredMentionOptions, ...externalMentions];
    }, [filteredMentionOptions, externalMentions]);

    const relevantMentions: RelevantMentionSection[] = useMemo(() => {
      return [
        {
          data: filteredMentionOptions,
          title: t('CHAT.MENTIONS.INTERNAL'),
        },
        {
          data: externalMentions,
          title: t('CHAT.MENTIONS.EXTERNAL'),
        },
      ];
    }, [filteredMentionOptions, t, externalMentions]);

    const updateCursorIndex = () => {
      const selection = window.getSelection();
      if (!selection || selection.rangeCount === 0) {
        return;
      }

      const range = selection.getRangeAt(0);
      const { startContainer, startOffset } = range;

      let index: number = startOffset;
      let node: Node | null = startContainer;

      while (node && node !== inputRef.current) {
        while (node.previousSibling) {
          node = node.previousSibling;

          if (node.nodeType === Node.TEXT_NODE || node.nodeType === Node.ELEMENT_NODE) {
            index += node.textContent?.length ?? 0;
          }
        }

        node = node.parentNode;
      }

      setCursorIndex(index);
    };

    const handleEnter = () => {
      if (mentionInProgress) {
        let correctSuggestionIndex = suggestionIndex;
        if (suggestionIndex > suggestions.length - 1) {
          correctSuggestionIndex = 0;
        }
        setMention(`@${suggestions[correctSuggestionIndex]?.label}`);
        return;
      }

      const canSendMessage = !sendDisabled;
      if (canSendMessage) {
        sendMessage();
      }
    };

    const handleArrowDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (mentionInProgress) {
        event.preventDefault();
        setSuggestionIndex(prevIndex => (prevIndex + 1) % suggestions.length);
      }
    };

    const handleArrowUp = (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (mentionInProgress) {
        event.preventDefault();
        setSuggestionIndex(prevIndex => (prevIndex === 0 ? suggestions.length - 1 : prevIndex - 1));
      }
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
      switch (event.code) {
        case 'Enter':
        case 'NumpadEnter':
          handleEnter();
          break;
        case 'ArrowDown':
          handleArrowDown(event);
          break;
        case 'ArrowUp':
          handleArrowUp(event);
          break;
        case 'ArrowLeft':
        case 'ArrowRight':
          requestAnimationFrame(updateCursorIndex);
          break;
        default:
          break;
      }
    };

    const insertText = useCallback(
      (text: string, from: number = cursorIndex, to: number = cursorIndex) => {
        return `${message.slice(0, from)}${text} ${message.slice(to, message.length)}`;
      },
      [cursorIndex, message],
    );

    const setMention = useCallback(
      (mention: string) => {
        const currentMention = getMentionInProgress(message, mentionsRegex, cursorIndex);
        const mentionIndex = message.lastIndexOf(currentMention);
        const newMessage = insertText(mention, mentionIndex, mentionIndex + currentMention.length);

        // + 1 to take the space after the mention into account
        const newTextInputIndex = mentionIndex + mention.length + 1;
        setTextAndCursorPosition(newMessage, newTextInputIndex);
      },
      [message, mentionsRegex, cursorIndex, insertText, setTextAndCursorPosition],
    );

    const handleOnHoverSuggestion = (index: number) => {
      setSuggestionIndex(index);
    };

    const getNewCursorPosition = () => {
      const editor = inputRef.current;
      if (!editor) {
        return null;
      }

      const selection = window.getSelection();
      if (!selection || selection.rangeCount === 0) {
        return null;
      }

      const range = selection.getRangeAt(0);
      const preRange = range.cloneRange();
      preRange.selectNodeContents(inputRef.current);
      preRange.setEnd(range.startContainer, range.startOffset);
      const cursorOffset = preRange.toString().length;

      return cursorOffset;
    };

    const restoreCursorPosition = (cursorOffset: number | null) => {
      if (cursorOffset === null) {
        return;
      }

      const editor = inputRef.current;
      if (!editor) {
        return;
      }

      let offset = cursorOffset;
      const selection = window.getSelection();
      const range = document.createRange();
      range.setStart(editor, 0);
      range.collapse(true);

      const traverseNodes = (node: Node) => {
        if (node.nodeType === Node.TEXT_NODE) {
          if (node.textContent!.length >= offset) {
            range.setStart(node, offset);
            range.collapse(true);
            return true;
          } else {
            offset -= node.textContent!.length;
          }
        } else {
          for (const child of Array.from(node.childNodes)) {
            if (traverseNodes(child)) {
              return true;
            }
          }
        }
        return false;
      };

      traverseNodes(editor);

      selection?.removeAllRanges();
      selection?.addRange(range);

      if (containerRef.current) {
        const parentRect = containerRef.current.getBoundingClientRect();
        setCursorPositionX(range.getBoundingClientRect().left - parentRect.left);
      }
    };

    const handleInput = () => {
      const editor = inputRef.current;
      if (!editor) {
        return;
      }

      const cursorIndex = getNewCursorPosition();

      setTextAndCursorPosition(editor.textContent ?? '', cursorIndex ?? (editor.textContent?.length ?? 1) - 1);
    };

    const renderSuggestionSectionListItem = useCallback(
      (label: string, index: number, suggestionIndex: number) => {
        return (
          <li className={'suggestion-item' + (index === suggestionIndex ? ' item-active' : '')} key={index}>
            <button onClick={() => setMention(`@${label}`)} onMouseEnter={() => handleOnHoverSuggestion(index)}>
              @{label}
            </button>
          </li>
        );
      },
      [setMention],
    );

    const suggestionSectionList = useMemo(() => {
      let index = 0;
      let startPointList = cursorPositionX;
      const endPointContainer = containerRef.current?.getBoundingClientRect().right ?? 0;
      const endPointList = suggestionListRef.current?.getBoundingClientRect().right ?? 0;
      if (endPointList > endPointContainer) {
        startPointList = startPointList - (endPointList - endPointContainer);
      }
      return (
        !!mentionInProgress &&
        isInputFocused && (
          <div
            className={'suggestions-list-container'}
            ref={suggestionListRef}
            style={{ left: startPointList, maxHeight: `${windowHeight - 116}px` }}
          >
            <ul className={'suggestions-list'}>
              {relevantMentions.map(value => {
                if (!value.data.length) {
                  return null;
                }
                return (
                  <div key={`section-${value.title}`}>
                    <div className={'section-header-container'}>
                      <Icon colorName={COLORS.zulu.$08} name="peopleProfileTrioCenterTemplateOutline" />
                      <span className={'section-header-title'}>{value.title}</span>
                    </div>
                    <ul>
                      {value.data.map(item => {
                        const currentIndex = index;
                        index++;

                        return renderSuggestionSectionListItem(item.label, currentIndex, suggestionIndex);
                      })}
                    </ul>
                  </div>
                );
              })}
            </ul>
          </div>
        )
      );
    }, [
      cursorPositionX,
      isInputFocused,
      mentionInProgress,
      relevantMentions,
      renderSuggestionSectionListItem,
      suggestionIndex,
      windowHeight,
    ]);

    return (
      <div className="input-field" ref={containerRef}>
        {icon}
        {suggestionSectionList}
        <div
          className={'message-input'}
          contentEditable="true"
          data-test={'mention-input'}
          onClick={updateCursorIndex}
          onFocus={() => setIsInputFocused(true)}
          onInput={handleInput}
          onKeyDown={handleKeyDown}
          placeholder={t('CHAT.INPUT.PLACEHOLDER')}
          ref={inputRef}
          role={'presentation'}
        ></div>
      </div>
    );
  },
);
