import { Dispatch, useCallback } from 'react';
import { GiftedMessage, Mention, MessageStatus } from 'types';
import { v4 as uuidv4 } from 'uuid';

import { MessageUnion, useUploadAttachment } from '../../../hooks';
import { sendOverSocket } from '../../../sockets/socket';
import { mapStringToUserMessage } from '../../../utils';
import { useAuthenticationContext } from '../../authentication';
import { ChatMessagesActionType } from '../chatMessagesAction.enum';
import { ChatMessagesActions } from '../chatMessagesAction.type';

export const useSendChatMessage = (dispatch: Dispatch<ChatMessagesActions>, flightroomId: string) => {
  const upload = useUploadAttachment();
  const { user } = useAuthenticationContext();

  const addMessage = useCallback(
    (message: MessageUnion, status: MessageStatus) => {
      message = { ...message, status };
      dispatch({ payload: message, type: ChatMessagesActionType.ADD_MESSAGE });
    },
    [dispatch],
  );

  const send = async (messageText: string, attachment?: File | null, mentions?: Mention[], id = uuidv4()) => {
    const message = mapStringToUserMessage(flightroomId, user!.externalId, id, attachment, messageText);

    addMessage(message, MessageStatus.PENDING);

    if (attachment) {
      try {
        message.attachment = await upload(attachment);
      } catch (error) {
        addMessage(message, MessageStatus.FAILED);
        return false;
      }
    }

    if (mentions) {
      message.mentions = mentions;
    }

    try {
      await sendOverSocket('message.create', message);
    } catch (error) {
      addMessage(message, MessageStatus.FAILED);
    }
  };

  const retry = async (message: GiftedMessage, pendingImage?: File | null) => {
    await send(message.text, pendingImage, message.mentions, message._id.toString());
  };

  const remove = (message: GiftedMessage) => {
    dispatch({ payload: message._id.toString(), type: ChatMessagesActionType.REMOVE_MESSAGE });
  };

  return { remove, retry, send };
};
