import { useTranslation } from 'react-i18next';
import { getIconForOccupation } from '@aviobook/_utils';
import { replaceJobTitlePlaceholders, UserV1Util, useUsersContext } from 'shared';
import { COLORS } from 'styles';
import { ConnectivityStatusV1, FlightRoomUser } from 'types';

import { StatusDot, Text } from '../_shared';
import { ProfileHeader } from './components/ProfileHeader';
import { ProfileSection } from './components/ProfileSection';

import './profile.scss';

export const CrewProfile = ({ crewMember }: { crewMember: FlightRoomUser }) => {
  const { t } = useTranslation();
  const { getUserById } = useUsersContext();

  const user = getUserById(crewMember.externalId);
  const jobTitle = crewMember.scheduled
    ? replaceJobTitlePlaceholders(crewMember.type.name)
    : UserV1Util.getDivision(user?.jobTitle);

  return (
    <aside className="profile-container">
      {!!user && (
        <>
          <ProfileHeader
            firstName={user.firstName}
            iconName={getIconForOccupation(crewMember.type.role || user?.jobTitle)}
            jobTitle={jobTitle}
            lastName={user.lastName}
          />
          <div className="profile-mid-bot-container">
            <section className="profile-section-mid-container">
              <div className="profile-section-mid-list-item">
                <div className="status-container">
                  <div>
                    <Text color={COLORS.romeo.$07} size="SM" weight="bold">
                      {t('PROFILE.STATUS')}
                    </Text>
                    <Text size="SM">
                      {user.connectivityStatus === ConnectivityStatusV1.ONLINE
                        ? t('PROFILE.CONNECTION_STATUS.ONLINE')
                        : t('PROFILE.CONNECTION_STATUS.OFFLINE')}
                    </Text>
                  </div>
                  <StatusDot size="medium" status={user?.connectivityStatus} userId={user?.externalId} />
                </div>
              </div>
              <ProfileSection icon="briefcase" title={t('PROFILE.JOB_TITLE')} value={user?.rank} />
              <ProfileSection icon="phone" title={t('PROFILE.PHONE_NUMBER')} value={user?.phoneNumber} />
              <ProfileSection icon="email" title={t('PROFILE.EMAIL')} value={user.email} />
            </section>
          </div>
        </>
      )}
    </aside>
  );
};
