import DOMPurify from 'dompurify';
import { create } from 'zustand';

import { BreadCrumbType } from '../_types/BreadCrumb';

interface BreadcrumbsState {
  crumbs: BreadCrumbType[];
  parsedCrumbs: BreadCrumbType[];
}

interface BreadcrumbsActions {
  add: (input: BreadCrumbType) => void;
  findAndReplace: (needle: string, replacement: BreadCrumbType) => void;
  init: (input: BreadCrumbType[]) => void;
  remove: (index: number) => void;
}

export const parseCrumbs = (input: BreadCrumbType[]): BreadCrumbType[] =>
  input
    .map(breadCrumb => ({ ...breadCrumb, url: DOMPurify.sanitize(breadCrumb.url) }))
    .filter(breadCrumb => breadCrumb.url?.length > 0);

const useBreadcrumbs = create<BreadcrumbsState & BreadcrumbsActions>()(set => {
  return {
    add: (input: BreadCrumbType) =>
      set(({ crumbs }) => {
        const concatenatedCrumbs = crumbs.concat(input);
        return {
          crumbs: concatenatedCrumbs,
          parsedCrumbs: parseCrumbs(concatenatedCrumbs),
        };
      }),
    crumbs: [],
    findAndReplace: (needle, replacement) =>
      set(({ crumbs }) => {
        const index = crumbs.findIndex(crumb => crumb.url.toLowerCase() === needle.toLowerCase());

        if (index >= 0) {
          crumbs.splice(index, 1, replacement);
        }

        return { crumbs, parsedCrumbs: parseCrumbs(crumbs) };
      }),
    init: input => set(() => ({ crumbs: input, parsedCrumbs: parseCrumbs(input) })),
    parsedCrumbs: [],
    remove: (index: number) =>
      set(({ crumbs }) => {
        if (crumbs.at(index)) {
          const newcrumbs = [...crumbs];

          newcrumbs.splice(index, 1);
          return { crumbs: newcrumbs, parsedCrumbs: parseCrumbs(newcrumbs) };
        }

        return { crumbs, parsedCrumbs: parseCrumbs(crumbs) };
      }),
  };
});

export default useBreadcrumbs;
