import React, { createContext, FC, ReactNode, useContext } from 'react';

export type EnvConfig = {
  appVersionNumber: string;
  connectServiceApiHost: string;
  connectServiceApiKey: string;
  firebaseApiKey?: string;
  firebaseAppId?: string;
  firebaseAuthDomain?: string;
  firebaseMessagingSenderId?: string;
  firebaseProjectId?: string;
  firebaseStorageBucket?: string;
  firebaseVapidKey?: string;
  installBundleId: string;
  nodeEnv: string;
};

const EnvConfigContext = createContext<EnvConfig | null>(null);

type EnvConfigProviderProps = {
  children: ReactNode;
  env: EnvConfig;
};

export const EnvConfigContextProvider: FC<EnvConfigProviderProps> = ({ children, env }) => {
  return <EnvConfigContext.Provider value={env}>{children}</EnvConfigContext.Provider>;
};

export const useEnvConfig = () => {
  const context = useContext(EnvConfigContext);
  if (!context) {
    throw new Error('useEnvConfig must be used within a EnvConfigProvider');
  }
  return context;
};
