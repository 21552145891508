import React, { useRef, useState } from 'react';
import { Icon } from '@aviobook/_shared';
import { Tooltip } from '@aviobook/_shared/tooltip/tooltip';
import { IconName } from 'assets';

type Props = {
  iconName: IconName;
  label: string;
};

export const IconWithTooltip = ({ iconName, label }: Props) => {
  const [isHovered, setIsHovered] = useState(false);
  const iconRef = useRef(null);

  return (
    <div className="wrapper" onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)} ref={iconRef}>
      <Icon name={iconName} />
      {isHovered && (
        <Tooltip alignment="top" anchor={iconRef?.current}>
          <slot name="content">{label}</slot>
        </Tooltip>
      )}
    </div>
  );
};
